import React, { useState } from "react";
import { Col, Modal, Row } from "antd";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TbAlertSquare } from "react-icons/tb";
import ReleaseNotes from "../../modules/ReleaseNotes";
import Disclaimer from "../../modules/Disclaimer";
import logo from "../../images/footer/VERTICAL_WEB_white.png";
import "./style.css";

function Footer() {
  const [isReleaseNoteOpen, setIsReleaseNoteOpen] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const showReleaseNote = () => {
    setIsReleaseNoteOpen(true);
  };

  const hideReleaseNote = () => {
    setIsReleaseNoteOpen(false);
  };

  const showDisclaimer = () => {
    setIsDisclaimerOpen(true);
  };

  const hideDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  return (
    <>
      <div className="msu_footer empty">
        <Row className="footer-row">
          <h6 className="footer-link">
            <button className="link-button" onClick={showReleaseNote}>
              <HiOutlineSpeakerphone style={{ marginBottom: "-2px" }} />{" "}
              <u>Release Notes</u>
            </button>
            {" | "}
            <button className="link-button" onClick={showDisclaimer}>
              <TbAlertSquare style={{ marginBottom: "-2px" }} />{" "}
              <u>Disclaimer</u>
            </button>
          </h6>
          <Col xs={{ span: 0 }} lg={{ span: 5, offset: 2 }}>
            <a
              href="https://www.gri.msstate.edu/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${logo}`}
                alt="MSU Logo"
                className="footer_image"
              />
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
            <hr style={{ borderColor: "#888888" }} />
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1, order: 1 }}
            lg={{ span: 9, offset: 2 }}
            className="mt-1"
          >
            <ul className="footer_left_nav ">
              <li>
                <a
                  href="https://www.msstate.edu/privacy"
                  className="text-white-shade"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Privacy Statement</u>
                </a>
              </li>
              <li>
                <a
                  href="https://www.policies.msstate.edu/policy/0302"
                  className="text-white-shade"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>EEO Statement</u>
                </a>
              </li>
              <li>
                <a
                  href="https://secure.ethicspoint.com/domain/media/en/gui/24520/index.html"
                  className="text-white-shade"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Ethics Line</u>
                </a>
              </li>
              <li>
                <a
                  href="https://www.msstate.edu/legal"
                  className="text-white-shade"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Legal Notices</u>
                </a>
              </li>
              <li>
                <a
                  href="https://www.msstate.edu/sites/www.msstate.edu/files/MSState%20ASR_FR_2022.pdf"
                  className="text-white-shade"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Annual Security & Fire Report</u>
                </a>
              </li>
            </ul>
            <br />
            <p className="text-white-shade">
              Copyright &copy; 2023 Mississippi State University. All Rights
              Reserved.
            </p>
          </Col>
          <Col
            xs={{ span: 22, offset: 1, order: 2 }}
            lg={{ span: 9, offset: 2 }}
          >
            <p className="text-white-shade text-align-right f11">
              Mississippi State University is an equal opportunity institution.
              Discrimination in university employment, programs or activities
              based on race, color, ethnicity, sex, pregnancy, religion,
              national origin, disability, age, sexual orientation, gender
              identity, genetic information, status as a U.S. veteran, or any
              other status protected by applicable law is prohibited. For more
              information, please contact the{" "}
              <a href="https://www.civilrights.msstate.edu/">
                <u>Office of Civil Rights Compliance</u>
              </a>
              .
            </p>
          </Col>
        </Row>
        <Col xs={{ span: 24 }} lg={{ span: 20, offset: 2 }}>
          <hr style={{ borderColor: "#888888" }} />
        </Col>
        <Row justify="center">
          <Col>
            <div className="text-white-shade">
              Technical problems, contact the{" "}
              <a href="mailto:webmaster@gri.msstate.edu">
                <u>Webmaster</u>
              </a>{" "}
              - Last modified: 07/20/2023
            </div>
            <div className="text-white-shade">
              This website is hosted by the{" "}
              <u>
                <a href="https://www.hpc.msstate.edu/">
                  High Performance Computing Collaboratory
                </a>
              </u>
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </div>
      <>
        <Modal
          open={isReleaseNoteOpen}
          onCancel={hideReleaseNote}
          onOk={hideReleaseNote}
          width="60vw"
        >
          <ReleaseNotes />
        </Modal>
        <Modal
          open={isDisclaimerOpen}
          onCancel={hideDisclaimer}
          onOk={hideDisclaimer}
          width="60vw"
        >
          <Disclaimer />
        </Modal>
      </>
    </>
  );
}

export default Footer;
