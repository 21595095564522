import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import secureLocalStorage from "react-secure-storage";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import webtool from "../../images/homepage/web_tool.png";
import usdalogo from "../../images/homepage/usda_logo.png";
import eightstates from "../../images/homepage/eight_states.png";
import "./style.css";

function Home() {
  useEffect(() => {
    // process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX= 'vara';
    secureLocalStorage.setItem("na", 12);
    secureLocalStorage.setItem("s1", "vara 12");
    secureLocalStorage.setItem("ba", true);
    let value = secureLocalStorage.getItem("ba");
  }, []);

  return (
    <>
      <Header />
      <div className="sliderimg">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
            <div className="home-header-title">
              <h1 className="crpheader">CRP Menu Tool</h1>
              <div className="crpslidertext">
                <div>
                  <p className="subtitle">
                    Find conservation practices that fit your goals
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <a href="https://www.usda.gov/" target="_blank">
                    <img src={usdalogo} className="img80" />
                  </a>
                </div>
                <h3 className="homepage-card-content">
                  The Conservation Reserve Program (CRP) is an initiative
                  implemented by the United States Department of Agriculture
                  (USDA) that aims to enhance environmental quality through the
                  conservation of sensitive lands. CRP is playing a crucial role
                  in preserving biodiversity, mitigating climate change impacts,
                  and sustaining rural economies.
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a href="/CRPBasics" className="btn btn-primary big-maroon-btn">
                  Learn More About CRP
                </a>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <img src={eightstates} className="img80" />
                </div>
                <h3 className="homepage-card-content">
                  By defining the location and conservation goals for agricultural
                  land, CRP Menu Tool is able to filter out practices that are
                  available in your area and address your environmental concerns.
                  Currently, CRP Menu Tool is developed for Mississippi, Missouri,
                  Illinois, and Iowa. Our next expansion will include Ohio, Indiana,
                  Arkansas, and Louisiana. 
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a
                  href="/PracticeList"
                  className="btn btn-primary big-maroon-btn"
                >
                  Explore Available Practices
                </a>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
          <Card className="homepage-card">
            <Row justify="center">
              <Col xs={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 0 }}>
                <div className="homepage-card-icon">
                  <img src={webtool} className="img80" />
                </div>
                <h3 className="homepage-card-content">
                  The CRP Menu Tool is an online platform designed to help users
                  explore and learn more about conservation practices offered
                  through the Conservation Reserve Program (CRP) of the U.S.
                  Farm Bill. Users can find conservation practices based on
                  landowner interests and location, get soil rental rate
                  estimates, check practice eligibility, and learn how to
                  enroll.
                </h3>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} className="homepage-card-button text-align-center">
                <a href="/Map" className="btn btn-primary big-maroon-btn">
                  Check Field Eligibility
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
}

export default Home;
