import React, { useEffect, useState } from "react";
import { Button, Rate } from "antd";
import { HeartOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  TbHexagonLetterC,
  TbHexagonLetterP,
  TbHexagonLetterR,
  TbHexagonLetterS,
  TbSquareLetterC,
  TbSquareLetterG,
} from "react-icons/tb";
import { MdOutlineCancel } from "react-icons/md";
import secureLocalStorage from "react-secure-storage";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import {
  getPracticeIncentives,
  getPracticePriorities,
} from "../../utils/helperFunctions";
import "./style.css";

const PrintView = ({ userFavoritePractices }) => {
  const practiceData =
    JSON.parse(secureLocalStorage.getItem("printPracticeData")) || [];
  const filterSelections =
    JSON.parse(secureLocalStorage.getItem("printFilterSelections")) || {};

  function titleCase(str) {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  const date = new Date();
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear() - 2000;
  let currentDate = `${month}/${day}/${year}`;

  const isLoggedIn = secureLocalStorage.getItem("token");

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    priorityecosystem: "Ecosystem",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
  };
  return (
    <>
      <Header noNavigation={"print"} />
      <div className="print-filter-results">
        <h3 style={{ float: "right", marginRight: "30px" }}>
          {`Location: ${filterSelections.county}, ${filterSelections.state}`}
          <span style={{ float: "right" }}>Date:{currentDate}</span>
        </h3>
        <h3>Available Practices: {practiceData?.length}</h3>
        {filterSelections.landCover?.length > 0 && (
          <h3>
            {filterSelections.landCover?.length
              ? "Selected Land Cover Types: "
              : null}
            {filterSelections.landCover?.map((type, index) => (
              <b key={index} className="filter-tag">
                {getLabels[type]}
              </b>
            ))}
          </h3>
        )}
        {filterSelections.priorities?.length > 0 && (
          <h3>
            {filterSelections.priorities?.length
              ? "Selected Priorities: "
              : null}
            {filterSelections.priorities?.map((type, index) => (
              <b key={index} className="filter-tag">
                {getLabels[type]}
              </b>
            ))}
          </h3>
        )}

        <Button
          onClick={() => window.print()}
          className="print-button filter-button"
        >
          <b>
            <PrinterOutlined /> Print
          </b>
        </Button>

        <Button onClick={() => window.close()} className="close-button ">
          <b className="close-text">
            <MdOutlineCancel /> <span>Close</span>
          </b>
        </Button>
      </div>
      <div className="print-container">
        {practiceData?.map((practice, index) => {
          return (
            <div className="for-print-margin">
              <div className="print-practice-container" key={index}>
                <h2 className="print-practice-title">{`${
                  practice.pnumber
                }: ${titleCase(practice.pname)}`}</h2>
                <div className="print-practice-match">
                  {/* {isLoggedIn !== null && (
                    <Rate
                      character={<HeartOutlined />}
                      count="1"
                      value={
                        userFavoritePractices.includes(practice.title) ? 1 : 0
                      }
                      className="text-white"
                      // onChange={(val) =>
                      //   favOnchange(
                      //     val,
                      //     practice.title + "#" + practice.pdflink
                      //   )
                      // }
                    />
                  )} */}
                  {!!practice.practiceScore && practice.practiceScore >= 5 && (
                    <p className="practice-best-match">Best Match</p>
                  )}
                  {!!practice.practiceScore &&
                    practice.practiceScore >= 3 &&
                    practice.practiceScore < 5 && (
                      <p className="practice-good-match">Good Match</p>
                    )}
                  {!!practice.practiceScore &&
                    practice.practiceScore >= 1 &&
                    practice.practiceScore < 3 && (
                      <p className="practice-fair-match">Fair Match</p>
                    )}
                </div>
                {practice.mostCommon && (
                  <div class="print-most-common most-common">Most Common</div>
                )}

                {
                  <div>
                    <b>Land Cover:</b>
                    <ul className="print-ul">
                      {practice.practiceCovers.map((cover, index) => {
                        return <li key={index}>{getLabels[cover]}</li>;
                      })}
                    </ul>
                  </div>
                }

                {
                  <div>
                    <b>Priorities:</b>
                    <ul className="print-ul">
                      {practice.practicePriorities.length < 1 ? (
                        <p>
                          Specific priorities have not been identified for this
                          practice.
                        </p>
                      ) : (
                        practice.practicePriorities.map((priority, index) => {
                          return <li key={index}>{getLabels[priority]}</li>;
                        })
                      )}
                    </ul>
                  </div>
                }

                <div>
                  {practice && (
                    <div>
                      <b>Incentives: </b>
                      <div className="print-incentive-container">
                        {practice.practiceIncentives.length < 1 && (
                          <p>No additional incentives</p>
                        )}

                        {practice["20_p_rr_incentive"] && (
                          <b className="print-incentive-percent">
                            <TbHexagonLetterR
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            Rental Rate Incentive: 20%
                          </b>
                        )}
                        {practice["50_p_pip"] && (
                          <b className="print-incentive-percent">
                            <TbHexagonLetterP
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            Practice Incentive: 50%
                          </b>
                        )}
                        {practice["climate_smrt_incentive"] !==
                          ("false" || "NA") && (
                          <b className="print-incentive-percent">
                            <TbHexagonLetterC
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            {`Climate Smart Incentive:
                            ${practice["climate_smrt_incentive"]}%`}
                          </b>
                        )}
                        {practice["32_5_p_sip"] && (
                          <b className="print-incentive-percent">
                            <TbHexagonLetterS
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            Signup Incentive: 32.5%
                          </b>
                        )}
                      </div>
                    </div>
                  )}

                  {practice && (
                    <div>
                      <b>Signups: </b>
                      <div className="print-signup-container">
                        {practice.general && (
                          <b className="print-incentive-percent print-signup">
                            <TbSquareLetterG
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            Available In General Signup
                          </b>
                        )}
                        {practice.continous && (
                          <b className="print-incentive-percent print-signup">
                            <TbSquareLetterC
                              size="2em"
                              color="maroon"
                              style={{ marginRight: "3px" }}
                            />
                            Available In Continuous Signup
                          </b>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
};

export default PrintView;
