import React from "react";
import { Col, Row, Form, Checkbox, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import aquaculture from "../../../images/landscape/AquaculturePond.png";
import cropland from "../../../images/landscape/Cropland.png";
import timber from "../../../images/landscape/Timber.png";
import pasture from "../../../images/landscape/Pasture.png";
import wetland from "../../../images/landscape/Wetland.png";
import "../style.css";

const CheckboxGroup = Checkbox.Group;

function Step2({ selectedPriorities, selectedCheckboxes }) {
  const allPriorities = [
    {
      src: aquaculture,
      alt: "aquaculture",
      label: "Aquaculture Ponds",
      value: "aquaculture",
      qContent: "Land used for commercial pond-raised aquaculture.",
    },
    {
      src: cropland,
      alt: "Cropland",
      label: "Cropland",
      value: "cropland",
      qContent: "Area used for the production of crops for harvest, either cultivated or non-cultivated.",
    },
    // {
    //   src: timber,
    //   alt: "timber",
    //   label: "Timber",
    //   value: "timber",
    // },
    {
      src: pasture,
      alt: "pasture",
      label: "Pasture",
      value: "pasture",
      qContent: "Grazing lands comprised of either introduced or native forage species.",
    },
    {
      src: wetland,
      alt: "wetland",
      label: "Wetland",
      value: "wetland",
      qContent: "Includes areas with generally wet soils that may flood for all- or part of the year.",
    },
  ];
  return (
    <>
      <Row>
        <Col className="mt-3">
          <div>
            <b>2) Does your land you are considering for CRP enrollment/reenrollment include any of the following cover types? </b>
          </div>
        </Col>
      </Row>
      <Form>
        <Row gutter={40} className="mt-3 checkbox-list">
          <CheckboxGroup
            className="checkbox-group"
            onChange={selectedPriorities}
            defaultValue={selectedCheckboxes}
          >
            {allPriorities.map((priority) => {
              return (
                <Checkbox className="checkbox-container" value={priority.value}>
                  <img
                    className="checkbox-image"
                    src={priority.src}
                    alt={priority.alt}
                  />
                  <Popover
                    overlayStyle={{
                      maxWidth: "20vw",
                    }}
                    placement="topRight"
                    content={priority.qContent}
                    trigger="hover"
                  >
                    <span className="pa">
                      <QuestionCircleOutlined />
                    </span>
                  </Popover>
                  <div className="checkbox-label">{priority.label}</div>
                </Checkbox>
              );
            })}
          </CheckboxGroup>
        </Row>
      </Form>
    </>
  );
}
export default Step2;
