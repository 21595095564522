import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, List } from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import aboutCRPImg1 from "../../images/about/aboutCRPMENU_1.png";
import EightStates from "../../images/about/eight_states.png";
import Outreach_MSU from "../../images/about/Outreach_MSU.png";
import Workshop from "../../images/about/Workshop.jpg";
import USDA from "../../images/about/USDAlogo.png";
import MSDWildlife from "../../images/about/WildlifeMississippi.png";
import PheasantsQuail from "../../images/about/PheasantsQuailForever.png";
import USFWS from "../../images/about/USFWSPartnersProgram.png";
import MissouriDept from "../../images/about/MissouriDeptConservation.png";
import Purdue from "../../images/about/Purdue.png";
import MFA from "../../images/about/MFA.png";
import Bayer from "../../images/about/BayerCropSciencelogo.png";
import AFT from "../../images/about/AFTlogo.jpg";
import MDWFP from "../../images/about/MDWFPlogo.png";
import NRCS from "../../images/about/NRCS.png";
import FSA from "../../images/about/FSALogo.png";


import "./style.css";

function CRPMenu() {
  const crpmenu_logos=[
    {title:"USDA",link:"https://www.usda.gov/",image:USDA},
    {title:"NRCS",link:"https://www.nrcs.usda.gov/",image:NRCS},
    {title:"FSA",link:"https://www.fsa.usda.gov/",image:FSA},
    {title:"MSDWildlife",link:"https://wildlifemiss.org/",image:MSDWildlife},
    {title:"PheasantsQuail",link:"https://www.pheasantsforever.org/",image:PheasantsQuail},
    {title:"USFWS",link:"https://www.fws.gov/",image:USFWS},
    {title:"MissouriDept",link:"https://mdc.mo.gov/",image:MissouriDept},
    {title:"Purdue",link:"https://www.purdue.edu/",image:Purdue},
    {title:"MFA",link:"https://mfa-inc.com/",image:MFA},
    {title:"Bayer",link:"https://www.bayer.com/en/",image:Bayer},
    {title:"AFT",link:"https://farmland.org/",image:AFT},
    {title:"MDWFP",link:"https://www.mdwfp.com/wildlife-hunting/private-lands-program/",image:MDWFP},
    
  ];
  return (
    <>
      <Header />
      <Row>
        <Col offset={2}>
          <Row>
            <Col xs={{ span: 20, offset: 0 }} lg={{ span: 15, offset: 0,}}>
              <h2><b>About the CRP Menu Project</b></h2>
            </Col>
            <Col xs={{ span: 22, offset: 0, order: 2 }} lg={{ span: 14, offset: 0, order: 1 }} >
              <h3 className="mt-3">Overview & Goals</h3>
              <div className="mt-3 about-paragraph text-justify">
                <p>
                  In partnership with the U.S. Department of Agriculture, Mississippi State University's Geosystems Research Institute and Forest & Wildlife Research Center are building a web-enabled "CRP Menu Tool" to provide landowners the opportunity to explore practices under the Conservation Reserve Program (CRP) supported by the U.S. Farm Bill. Our goal is to increase landowner understanding and engagement by improving access to information on conservation practice availability and incentives based on their location, interests, and goals. Additional features would allow landowners to explore potential enrollment scenarios alongside USDA staff and technical service providers.
                </p>
                <p>
                  The CRP Menu Tool provides a comprehensive set of features to support landowners in navigating conservation practices effectively. Users can access detailed descriptions of available conservation practices tailored to their specific state and county, facilitating informed decision-making. The tool establishes connections between landowner priorities and potential conservation practices, ensuring alignment with individual objectives. By offering estimates of rental rates derived from county averages and soil types, the tool assists in financial planning. Moreover, it outlines general eligibility scenarios based on location and objectives, simplifying the process for users. Additionally, the tool allows for the generation of user-specific reports, tailored to their interests and objectives, providing personalized guidance throughout the conservation planning process.
                </p>
              </div>
            </Col>
            <Col xs={{ span: 22,  order: 3 }} lg={{ span: 7, offset: 0, order:2 }}>
              <img src={aboutCRPImg1} className="mt-3 img-responsive" />
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={{ span: 22, order: 3 }} lg={{ span: 5, order: 1 }} className="">
              <img src={EightStates} className="mt-3" />
            </Col>
            <Col xs={{ span: 22, order: 2 }} lg={{ span: 16, offset: 1, order: 2 }} className="mt-3">
              <h3 className="mt-3">Current Coverage</h3>
              <div className="about-paragraph text-justify">
                <p>
                  CRP Menu Tool was originally developed in three pilot states, Mississippi, Missouri, and Illinois. The selection of the pilot states reflects a strategic approach aimed at capturing diverse perspectives within the agricultural landscape. These states were chosen to encompass a spectrum of CRP enrollment rates, spanning from high to low, thus providing insights into varied adoption patterns and factors influencing participation. Additionally, the diversity in cropland acreage across these regions offers a comprehensive understanding of land-use dynamics and conservation needs. Moreover, the inclusion of priority groups such as non-white, veteran, young, and beginning producers ensures representation from historically underserved communities, enabling us to address their unique challenges and requirements effectively. By engaging with stakeholders from these diverse backgrounds, the pilot initiative developed a robust tool that caters to the diverse needs and priorities of landowners across different contexts.
                </p>
                <p>
                  Development for the CRP Menu Tool continues to progress, as we prepare for a 5-state expansion. After visiting the pilot states, collecting feedback from landowners and practitioners, and revising CRP Menu Tool, we are confident in expanding our tool to include 5 new states, Iowa, Indiana, Ohio, Arkansas, and Louisiana. These states were developed subsequent to the pilot states due to the similarity in cropping history, CRP practice variety, and environmental concerns.  Moving forward, we are anticipating expanding CRP Menu Tool nationwide and incorporating a broader range of CRP information. 
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 22,  order: 2 }} lg={{ span: 16, offset: 0, order: 2 }} className="mt-3">
              <h3 className="mt-3">Stakeholder Engagement</h3>
              <div className="about-paragraph text-justify">
                <p>
                  We are working collaboratively with stakeholders to build this tool, including landowners, USDA staff, and conservation partners. This co-production, or human-centered, design approach is not only important to our team, but it also ensures that decision-support tools are effective and beneficial to end users. Our way to engage with stakeholders is through a series of workshops in the pilot states. Our goal is to invite feedback from a broad and inclusive set of landowners, USDA staff, and technical service providers on the tools we are developing and how to best meet their needs. Using a social science framework, we will explore questions on conservation practice motivations and barriers to adoption.
                </p>
                <p>
                  This project offers a unique opportunity to not only receive feedback on the CRP Menu Tool features, but also on the potential benefits of web-based decision-support tools for improving engagement with conservation programs.
                  Between late 2023 and early 2024, we conducted 12 in-person workshops and 3 virtual workshops with local landowners and agencies in three pilot states: Illinois, Mississippi, and Missouri. Please visit the
                  <Link to="/Workshops" style={{ color: "maroon" }}> Workshops </Link>
                  page to learn more.
                </p>
              </div>
            </Col>
            <Col xs={{ span: 22,  order: 3 }} lg={{ span: 5, offset: 0, order: 3 }} className="mt-3">
              <img src={Workshop} className="mt-3" />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <h3 className="mt-3">Steering Committee</h3>
              <div className="about-paragraph text-justify">
                <p>
                  Through the adoption of a co-production approach, the Steering Committee plays a pivotal role in guiding the development of the CRP Menu Tool, ensuring alignment with project objectives and maintaining momentum towards its successful realization. Comprised of key stakeholders and experts from various fields, the committee serves as a cohesive force, offering invaluable feedback and insights to inform strategic decisions and refine project trajectories. Their dedication and commitment are instrumental in navigating challenges, fostering collaboration, and driving innovation throughout the development process. We extend our heartfelt appreciation to these partners for their unwavering support and contributions, which are integral to the continued progress and effectiveness of the CRP Menu Tool in serving the needs of agricultural communities and promoting sustainable land management practices.
                </p>
                <p>The Steering Committee members include:</p>
              </div>
            </Col>
            <Col span={22} className="mt-3 text-justify">
              <List
                grid={{gutter: 16,xs: 1,sm: 2,md: 3,lg: 4,xl: 4,xxl: 4}}
                dataSource={crpmenu_logos}
                className="crpmenu_logos"
                renderItem={(item) => (
                  <List.Item>
                    <a href={item.link} target="_blank">
                      <img src={item.image} title={item.title}/></a>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <h3 className="mt-3">Sponsorship</h3>
              <p className="about-paragraph text-justify">
                This website is based upon work supported by the U.S. Department of Agriculture, Farm Production and Conservation program under agreement number FBC22CPT0012776. Any opinions, findings, conclusions, or recommendations expressed in this publication are those of the author(s) and do not necessarily reflect the views of the U.S. Department of Agriculture. In addition, any reference to specific brands or types of products or services does not constitute or imply an endorsement by the U.S. Department of Agriculture for those products or services. USDA is an equal opportunity provider, employer, and lender (https://www.usda.gov/non-discrimination-statement). This work is produced with support from the Mississippi State University Forest and Wildlife Research Center and Mississippi State University Geosystems Research Institute.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default CRPMenu;
