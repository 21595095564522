import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row, Select, Spin, Tooltip, Tour } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import FilterMap from "../../FilterMap";
import tutorialVideo from "../../../videos/explore_practices.mp4";
import "../style.css";

const Step1 = ({
  stateList,
  selectedState,
  stateChange,
  countyList,
  selectedCounty,
  countyChange,
  setClickedState,
  setClickedCounty,
  setClickedFips,
  selectedFips,
}) => {
  const { Option } = Select;
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [startTour, setStartTour] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      // Prevent default touchmove behavior
      const preventScroll = (e) => {
        e.preventDefault();
      };
      document.addEventListener("touchmove", preventScroll, { passive: false });

      return () => {
        document.body.style.overflow = "auto";
        document.removeEventListener("touchmove", preventScroll);
      };
    }
  }, [isOpen]);

  const steps = [
    {
      title: "Video Tutorial",
      description: "Please follow the steps in this video tutorial",
      cover: (
        <video width="400px" height="300px" controls>
          <source src={tutorialVideo} type="video/mp4"></source>
        </video>
      ),
      target: () => null,
    },
  ];

  return (
    <>
      <Tooltip title="Watch the video tutorial">
        <Button
          type="primary"
          onClick={() => setStartTour(true)}
          style={{ float: "right" }}
        >
          <VideoCameraOutlined />
        </Button>
      </Tooltip>
      <p>
        <br />
        <b>
          1) Available practices and practice standards vary by state. Please
          select your state and county to begin.
        </b>
        <br />
        <br />
      </p>

      <Form ref={inputRef} form={form}>
        <Row>
          <Col span={12} offset="">
            <Form.Item label="State">
              <Select
                className="select-font-size"
                showSearch
                defaultValue={selectedState}
                placeholder="Select State"
                onChange={stateChange}
                options={stateList}
                value={
                  selectedState ||
                  (stateList.length > 0 ? "Select State" : "No State")
                }
                onDropdownVisibleChange={(open) => setIsOpen(open)}
              />
            </Form.Item>
          </Col>

          <Col span={10} offset="1">
            <FormItem label="County">
              <Select
                className="select-font-size"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select County"
                defaultValue={selectedCounty}
                onChange={countyChange}
                options={countyList}
                value={
                  selectedCounty ||
                  (countyList.length > 0
                    ? "Select County"
                    : selectedState != null
                    ? "No County"
                    : "")
                }
                onFocus={(e) => {
                  // Prevent scrolling when focused
                  e.preventDefault();
                }}
                onDropdownVisibleChange={(open) => setIsOpen(open)}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <div className="filter-map">
            <br />
            <FilterMap
              setClickedState={setClickedState}
              setClickedCounty={setClickedCounty}
              setClickedFips={setClickedFips}
              selectedFips={selectedFips}
            />
          </div>
        </Row>
      </Form>
      <Tour
        open={startTour}
        onClose={() => setStartTour(false)}
        steps={steps}
      />
    </>
  );
};

export default Step1;
