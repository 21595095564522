import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Sidebar from "./Components/Sidebar";
import Map from "./Components/Map";
import NonGeoCheck from "./Components/NonGeoCheck";
import "./style.css";

function EligibilityCheck() {
	const [mapZoomLevel, setMapZoomLevel] = useState(4.5);
	const [mapViewBounds, setMapViewBounds] = useState({ _sw: { lng: -95.6, lat: 30.3 }, _ne: { lng: -89.1, lat: 41.3 } });
	const [selectedFips, setSelectedFips] = useState(null);
	const [clickedState, setClickedState] = useState(null);
	const [clickedCounty, setClickedCounty] = useState(null);
	const [zoomToCounty, setZoomToCounty] = useState(false);
	const [userLocation, setUserLocation] = useState({});
	const [pinCenter, setPinCenter] = useState(null);
	const [fieldBoundary, setFieldBoundary] = useState(null);
	const [selectedField, setSelectedField] = useState(null);
	const [drawingMode, setDrawingMode] = useState(false);
	const [editingMode, setEditingMode] = useState(false);
	const [pinningMode, setPinningMode] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<>
			<Header />
			<div className="eligibility-wrapper">
				{loading && <Spin className="spinner" tip="Loading" size="large" />}
				<div className="sidebar-wrapper">
					<Sidebar
						mapZoomLevel={mapZoomLevel}
						mapViewBounds={mapViewBounds}
						setSelectedFips={setSelectedFips}
						clickedState={clickedState}
						clickedCounty={clickedCounty}
						setZoomToCounty={setZoomToCounty}
						userLocation={userLocation}
						setUserLocation={setUserLocation}
						pinCenter={pinCenter}
						setPinCenter={setPinCenter}
						setFieldBoundary={setFieldBoundary}
						selectedField={selectedField}
						setSelectedField={setSelectedField}
						setDrawingMode={setDrawingMode}
						editingMode={editingMode}
						setEditingMode={setEditingMode}
						setPinningMode={setPinningMode}
						setLoading={setLoading}
					/>
				</div>
				<div className="map-wrapper">
					<Map
						setMapZoomLevel={setMapZoomLevel}
						setMapViewBounds={setMapViewBounds}
						selectedFips={selectedFips}
						setSelectedFips={setSelectedFips}
						setClickedState={setClickedState}
						setClickedCounty={setClickedCounty}
						zoomToCounty={zoomToCounty}
						userLocation={userLocation}
						pinCenter={pinCenter}
						setPinCenter={setPinCenter}
						fieldBoundary={fieldBoundary}
						selectedField={selectedField}
						setSelectedField={setSelectedField}
						drawingMode={drawingMode}
						editingMode={editingMode}
						pinningMode={pinningMode}
					/>
				</div>
			</div>
			<NonGeoCheck />
			<Footer />
		</>
	);
}

export default EligibilityCheck; 
