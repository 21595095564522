import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import secureLocalStorage from 'react-secure-storage';
import { Col, Row, Card, Spin, notification, Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FaAngleDoubleRight } from "react-icons/fa";
import "./style.css";
import myCommonApiCall from "../../Common/apicalls";
import hostURL from "../../Common/constants";

const authToken = secureLocalStorage.getItem("token");
const userId = secureLocalStorage.getItem("userRef");

function UserFavorites() {
  const [loading, setLoading] = useState(false);
  const [pastSearches, setPastSearches] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message
    });
  };

  useEffect(() => {
    setLoading(true);
    fetch(hostURL + "api/userfavourite/" + userId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken
      }
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        if (status === 200) {
          const itemsData = json.map((item) => ({
            key: item.id,
            state: item.state,
            county: item.county,
            practice: item.favTitle,
            pdflink: item.favPdfLink,
            searchedon: item.createdAt
          }));
          setPastSearches(itemsData);
          setLoading(false);
        } else if (status === 401) {
          myCommonApiCall.signOut();
        } else {
          openNotificationWithIcon("error", "Error", message);
        }
      });
  }, []);

  const deleteRecord = (record) => {
    const { key, state, county, practice } = record;
    const favTitle = practice;
    setLoading(true);
    fetch(`${hostURL}api/userfavourite/${userId}/${encodeURIComponent(favTitle)}/${encodeURIComponent(state)}/${encodeURIComponent(county)}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to delete');
        }
        return response.json();
      })
      .then((json) => {
        if (json.message === "User Favourite was deleted successfully!") {
          openNotificationWithIcon('success', 'Success', 'Record deleted successfully.');

          setPastSearches((prevSearches) => prevSearches.filter((item) => item.key !== key));
        } else {
          openNotificationWithIcon('error', 'Error', json.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Error', 'Failed to delete record.');
        setLoading(false);
      });
  };

  const DeleteButton = ({ record }) => (
    <Popconfirm
      title="Are you sure to delete this record?"
      onConfirm={() => deleteRecord(record)}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" danger icon={<DeleteOutlined />} />
    </Popconfirm>
  );

  return (
    <>
      <Spin spinning={loading}>
        <Row className="filter-practice-row-fav" span={20}>
          {pastSearches && pastSearches.length > 0 && pastSearches.map((ind, index) => (
            <Col span={5} offset={1} key={ind.key}>
              <Card
                className="practice-card-fav"
                title={ind.practice.split(":")[0]}
                headStyle={{ backgroundColor: "#5D1725", color: "white" }}
                extra={<DeleteButton record={ind} />} // Pass the correct record
              >
                <p>{ind.practice}</p>
                <p><b>State:</b> {ind.state}</p>
                <p><b>County:</b> {ind.county}</p>
                <p>
                  <a
                    href={`/Practice/${undefined
                      }/${btoa(ind.pdflink)}/${btoa(ind.practice)}`}
                    target="_blank"
                    className="detail-button"
                  >
                    <b>Details</b> <FaAngleDoubleRight />
                  </a>
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      {contextHolder}
    </>
  );
}

export default UserFavorites;
