import React from "react";
import Routes from "../routes";
import { ModuleContextProvider } from "../context/Context";
import "../App.css";
import { DirtyProvider } from "../utils/DirtyContext";

function App() {
  return (
    <>
      <DirtyProvider>
        <ModuleContextProvider>
          <Routes />
        </ModuleContextProvider>
      </DirtyProvider>
    </>
  );
}

export default App;
