import React, { useEffect, useState } from "react";
import { Button, Tooltip, Collapse } from "antd";

import parse from "html-react-parser";
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

//step2 Images
import aquaculture from "../../images/landscape/AquaculturePond.png";
import cropland from "../../images/landscape/Cropland.png";
import timber from "../../images/landscape/Timber.png";
import pasture from "../../images/landscape/Pasture.png";
import wetland from "../../images/landscape/Wetland.png";

//step3 Images
import aquatic from "../../images/priority/Aquatic.png";
import biggame from "../../images/priority/BigGame.png";
import carbonstorage from "../../images/priority/CarbonStorage.png";
import drought from "../../images/priority/Drought.png";
import watertemperature from "../../images/priority/WaterTemperature.png";
import excesswind from "../../images/priority/ExcessWind.png";
import flooding from "../../images/priority/Flooding.png";
import forestbirds from "../../images/priority/ForestBirds.png";
import grasslandbirds from "../../images/priority/GrasslandBirds.png";
import pollinators from "../../images/priority/Pollinators.png";
import snowdrift from "../../images/priority/SnowDrift.png";
import soilhealth from "../../images/priority/SoilHealth.png";
import watererosion from "../../images/priority/WaterErosion.png";
import waterfowl from "../../images/priority/Waterfowl.png";
import waterquality from "../../images/priority/WaterQuality.png";
import winderosion from "../../images/priority/WindErosion.png";
import priorityecosystem from "../../images/priority/PriorityEcosystem.png";

import "./style.css";
import Table from "./Components/Table";
import { LuPrinter } from "react-icons/lu";

const Practice = ({ practiceNumber }) => {
  const Panel = Collapse.Panel;
  // debugger;
  const [selectedPracticeData, setSelectedPracticeData] = useState(false);
  const prTitle = atob(window.location.pathname.split("/")[4]);
  const prCode = prTitle.split("-")[0].trim();
  const prName = atob(window.location.pathname.split("/")[4])
    .split(/-(.*)/)[1]
    .trim();
  const pdfLink = atob(window.location.pathname.split("/")[3]);
  const [activeKey, setActiveKey] = useState([]);
  const [isAccordion, setIsAccordion] = useState(true);
  useEffect(() => {
    (async () => {
      await fetch(
        `${hostURL}api/natpractices/${prCode}/${encodeURIComponent(prName)}`
      )
        .then((response) => response.json())
        .then((json) => {
          setSelectedPracticeData(json);
          console.log(json);
        })
        .catch((error) => console.error(error));
    })();
  }, []);

  const printing = () => {
    window.print();
    setActiveKey([]);
    setIsAccordion(true);
  };

  const handlePrint = () => {
    showAll();
    setTimeout(printing, 500);
  };

  const showAll = () => {
    setIsAccordion(false);
    setActiveKey([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  };

  const handleCollapseOpen = (key) => {
    if (key === activeKey) setActiveKey(0);
    else setActiveKey(key);
  };

  const newPracticeData = {
    id: selectedPracticeData.id,
    name: selectedPracticeData.pname,
    description: selectedPracticeData.descriptn,
    programs: [
      {
        name: "General Signup",
        period: selectedPracticeData.cntrctpd,
        incentives: {
          climateSmart: selectedPracticeData.climate_smrt_incentive,
          sip: selectedPracticeData["32_5_p_sip"],
          pip: selectedPracticeData["50_p_pip"],
          rentalRateIncentive: false,
          rentalRateAdj: false,
        },
      },
      {
        name: "Continuous Signup",
        period: selectedPracticeData.cntrctprdthrucontinous,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethrucontinous,
          sip: selectedPracticeData.sipthrucontinous,
          pip: selectedPracticeData.pipthrucontinous,
          rentalRateIncentive:
            selectedPracticeData.rentalrateincentivethrucontinuous,
          rentalRateAdj: false,
        },
      },
      {
        name: "HELI",
        period: selectedPracticeData.cntrctprdthruheli,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethruheli,
          sip: selectedPracticeData.sipthruheli,
          pip: selectedPracticeData.pipthruheli,
          rentalRateIncentive: false,
          rentalRateAdj: false,
        },
      },
      {
        name: "Wellhead Protection",
        period: selectedPracticeData.cntrctprdthruwellhead,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethruwellhead,
          sip: selectedPracticeData.sipthruwellhead,
          pip: selectedPracticeData.pipthruwellhead,
          rentalRateIncentive: false,
          rentalRateAdj: false,
        },
      },
      {
        name: "Farmable Wetland Program",
        period: selectedPracticeData.cntrctprdthrufwp,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethrufwp,
          sip: selectedPracticeData.sipthrufwp,
          pip: selectedPracticeData.pipthrufwp,
          rentalRateIncentive: false,
          rentalRateAdj: selectedPracticeData.rentalrateadjthrufwp,
        },
      },
      {
        name: "SAFE",
        period: selectedPracticeData.cntrctprdthrusafe,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethrusafe,
          sip: selectedPracticeData.sipthrusafe,
          pip: selectedPracticeData.pipthrusafe,
          rentalRateIncentive: selectedPracticeData.rentalrateincentivesafe,
          rentalRateAdj: false,
        },
      },
      {
        name: "CLEAR30",
        period: selectedPracticeData.cntrctprdthruclear30,
        incentives: {
          climateSmart: selectedPracticeData.climatesmartincentivethruclear30,
          sip: selectedPracticeData.sipthruclear30,
          pip: selectedPracticeData.pipthruclear30,
          rentalRateIncentive: false,
          rentalRateAdj: selectedPracticeData.rentalrateadjthruclear30,
        },
      },
    ],
    priorities: [
      {
        name: "Wind Erosion",
        eligible: selectedPracticeData.winderosion,
        imgSrc: winderosion,
      },
      {
        name: "Water Erosion",
        eligible: selectedPracticeData.watererosion,
        imgSrc: watererosion,
      },
      {
        name: "Soil Health",
        eligible: selectedPracticeData.soilhealth,
        imgSrc: soilhealth,
      },
      {
        name: "Excess Wind",
        eligible: selectedPracticeData.excesswind,
        imgSrc: excesswind,
      },
      {
        name: "Drought",
        eligible: selectedPracticeData.drought,
        imgSrc: drought,
      },
      {
        name: "Water Temperature",
        eligible: selectedPracticeData.elevatedwatertemperature,
        imgSrc: watertemperature,
      },
      {
        name: "Flooding",
        eligible: selectedPracticeData.flooding,
        imgSrc: flooding,
      },
      {
        name: "Snow Drift",
        eligible: selectedPracticeData.snowdrift,
        imgSrc: snowdrift,
      },
      {
        name: "Water Quality",
        eligible: selectedPracticeData.waterquality,
        imgSrc: waterquality,
      },
      {
        name: "Aquatic",
        eligible: selectedPracticeData.aquatic,
        imgSrc: aquatic,
      },
      {
        name: "Big Game",
        eligible: selectedPracticeData.biggame,
        imgSrc: biggame,
      },
      {
        name: "Grassland Animals",
        eligible: selectedPracticeData.grasslandbirds,
        imgSrc: grasslandbirds,
      },
      {
        name: "Forest Animals",
        eligible: selectedPracticeData.forestbirds,
        imgSrc: forestbirds,
      },
      {
        name: "Pollinators",
        eligible: selectedPracticeData.pollinators,
        imgSrc: pollinators,
      },
      {
        name: "Waterfowl",
        eligible: selectedPracticeData.waterfowl,
        imgSrc: waterfowl,
      },
      {
        name: "Carbon Storage",
        eligible: selectedPracticeData.carbonstorage,
        imgSrc: carbonstorage,
      },
      {
        name: "Ecosystem",
        eligible: selectedPracticeData.priorityecosystem,
        imgSrc: priorityecosystem,
      },
      {
        name: "Aquaculture Ponds",
        eligible: selectedPracticeData.aquaculture,
        imgSrc: aquaculture,
      },
      {
        name: "Cropland",
        eligible: selectedPracticeData.cropland,
        imgSrc: cropland,
      },
      { name: "Timber", eligible: selectedPracticeData.timber, imgSrc: timber },
      {
        name: "Pasture",
        eligible: selectedPracticeData.pasture,
        imgSrc: pasture,
      },
      {
        name: "Wetland",
        eligible: selectedPracticeData.wetland,
        imgSrc: wetland,
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header />
      <Button
        onClick={() => handlePrint()}
        className="report-print-button details-print-button"
      >
        <LuPrinter className="report-button-icon" /> Print
      </Button>
      {selectedPracticeData && (
        <div className="practice-page">
          <h3>
            <u>{prTitle}</u>
          </h3>
          {selectedPracticeData.descriptn && (
            <>
              <h4>Description:</h4>
              <p>{selectedPracticeData.descriptn}</p>
            </>
          )}

          <h4>Related Priorities:</h4>

          {newPracticeData && (
            <div>
              <p className="step3_images">
                {newPracticeData.priorities.map((priority) => {
                  return (
                    priority.eligible && (
                      <Tooltip placement="topLeft" title={priority.name}>
                        <img
                          alt={`line art logo that represents ${priority.name}`}
                          className="img_selected_cb"
                          src={priority.imgSrc}
                        />
                      </Tooltip>
                    )
                  );
                })}
              </p>
            </div>
          )}

          <Table newPracticeData={newPracticeData} />
          <p>
            This practice is not eligible for enrollment through:&nbsp;
            {newPracticeData.programs
              .filter((program) => program.period === "false")
              .map((program, index, arr) => {
                if (index === arr.length - 1 && arr.length > 1) {
                  return ` or ${program.name}`;
                }
                return program.name;
              })
              .join(", ")
              .replace(/,(\s*or)/, "$1")}
            .
          </p>

          <div className="cost-maint-manage">
            <Collapse
              header=""
              accordion={isAccordion}
              className="mt-3 crp-basics-paragraph"
              activeKey={activeKey}
            >
              {selectedPracticeData.costshareavble && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(1);
                  }}
                  header="Cost share available for"
                  key="1"
                >
                  {parse(selectedPracticeData.costshareavble)}
                </Panel>
              )}

              {selectedPracticeData.maintenance && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(3);
                  }}
                  header="Maintenance"
                  key="3"
                >
                  {parse(selectedPracticeData.maintenance)}
                </Panel>
              )}
              {selectedPracticeData.maintenanceforclear30 && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(4);
                  }}
                  header="Maintenance for Clear30"
                  key="4"
                >
                  {parse(selectedPracticeData.maintenanceforclear30)}
                </Panel>
              )}
              {selectedPracticeData.management && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(5);
                  }}
                  header="Management"
                  key="5"
                >
                  {parse(selectedPracticeData.management)}
                </Panel>
              )}
              {selectedPracticeData.eligibilityforheli && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(6);
                  }}
                  header="Eligibility for Heli"
                  key="6"
                >
                  {parse(selectedPracticeData.eligibilityforheli)}
                </Panel>
              )}
              {selectedPracticeData.eligiblityforwellhead && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(7);
                  }}
                  header="Eligibility for Wellhead Protection"
                  key="7"
                >
                  {parse(selectedPracticeData.eligiblityforwellhead)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythrucontinuous && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(8);
                  }}
                  header="Eligibility through Continuous"
                  key="8"
                >
                  {parse(selectedPracticeData.eligiblitythrucontinuous)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythruclear30 && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(9);
                  }}
                  header="Eligibility through Clear30"
                  key="9"
                >
                  {parse(selectedPracticeData.eligiblitythruclear30)}
                </Panel>
              )}
              {selectedPracticeData.eligiblitythrufwp && (
                <Panel
                  onClick={() => {
                    handleCollapseOpen(10);
                  }}
                  header="Eligibility through FWP"
                  key="10"
                >
                  {parse(selectedPracticeData.eligiblitythrufwp)}
                </Panel>
              )}
            </Collapse>
            <br />
          </div>

          {pdfLink !== "false" && windowWidth > 1000 ? (
            <div className="embed-responsive">
              <hr className="my-4" />
              <h4>
                <u>{prTitle}</u>
              </h4>
              <iframe
                title={prTitle}
                className="embed-responsive-item"
                src={`/Practices/${pdfLink}`}
                allowFullScreen
              />
            </div>
          ) : (
            <>
              <h4>
                <u>{prTitle} Fact Sheet</u>
              </h4>
              <p>
                Download to view the{" "}
                <a
                  className="pdf-link"
                  href={`/Practices/${pdfLink}`}
                  download={true}
                >
                  USDA Fact Sheet
                </a>
              </p>
            </>
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default Practice;
