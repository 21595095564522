import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import secureLocalStorage from 'react-secure-storage';
import { Col, Row, Table, Spin, notification, Popconfirm, Button, Card, Tooltip, Modal, Form, Input } from "antd";
import { FileSearchOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
import "./style.css";
import myCommonApiCall from "../../Common/apicalls";
import hostURL from '../../Common/constants';


//step2 Images
import aquaculture from "../../images/landscape/AquaculturePond.png";
import cropland from "../../images/landscape/Cropland.png";
import timber from "../../images/landscape/Timber.png";
import pasture from "../../images/landscape/Pasture.png";
import wetland from "../../images/landscape/Wetland.png";

//step3 Images
import aquatic from "../../images/priority/Aquatic.png";
import biggame from "../../images/priority/BigGame.png";
import carbonstorage from "../../images/priority/CarbonStorage.png";
import drought from "../../images/priority/Drought.png";
import watertemperature from "../../images/priority/WaterTemperature.png";
import excesswind from "../../images/priority/ExcessWind.png";
import flooding from "../../images/priority/Flooding.png";
import forestbirds from "../../images/priority/ForestBirds.png";
import grasslandbirds from "../../images/priority/GrasslandBirds.png";
import pollinators from "../../images/priority/Pollinators.png";
import snowdrift from "../../images/priority/SnowDrift.png";
import soilhealth from "../../images/priority/SoilHealth.png";
import watererosion from "../../images/priority/WaterErosion.png";
import waterfowl from "../../images/priority/Waterfowl.png";
import waterquality from "../../images/priority/WaterQuality.png";
import winderosion from "../../images/priority/WindErosion.png";
import priorityecosystem from "../../images/priority/PriorityEcosystem.png";

const authToken = secureLocalStorage.getItem("token");
const userId = secureLocalStorage.getItem("userRef");

function PastActivity() {
  const [loading, setLoading] = useState(false);
  const [pastSearches, setPastSearches] = useState(false);
  const [searchAgainData, setSearchAgainData] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [toUpdatedRecordId, setToUpdatedRecordId] = useState(null);
  const [updatedRecordNameModalOpen, setupdatedRecordNameModalOpen] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message
    });
  };

  const getLabels = {
    winderosion: "Wind Erosion",
    watererosion: "Water Erosion",
    soilhealth: "Soil Health",
    excesswind: "Excess Wind",
    drought: "Drought",
    elevatedwatertemperature: "Water Temperature",
    flooding: "Flooding",
    snowdrift: "Snow Drift",
    waterquality: "Water Quality",
    aquatic: "Aquatic",
    biggame: "Big Game",
    grasslandbirds: "Grassland Birds",
    forestbirds: "Forest Birds",
    pollinators: "Pollinators",
    waterfowl: "Waterfowl",
    carbonstorage: "Carbon Storage",
    aquaculture: "Aquaculture Ponds",
    cropland: "Cropland",
    timber: "Timber",
    pasture: "Pasture",
    wetland: "Wetland",
    priorityecosystem: "Ecosystem",
  };

  const columns = [
    {
      title: "Route",
      dataIndex: "route"
    },
    {
      title: "subRoute",
      dataIndex: "subroute"
    },
    {
      title: "Searched On",
      dataIndex: "searchedon"
    }
  ];

  const getSavedSearchData = () => {
    fetch(hostURL + "api/usersearchdata/" + userId, {
      // credentials: 'include',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken
      }
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;

        if (status == 200) {
          const itemsData = json.map((item) => {
            const formData = JSON.parse(item.formData);
            // const checkedOptions =  (formData.step2CheckedTypes)? ((formData.step2CheckedTypes).concat(formData.step3CheckedTypes)).join(', '): '';
            // const childrenData = `<div>Selected State: <b>${
            //   formData.selectedState
            // }</b>, Selected County: <b>${formData.selectedCounty}</b> <br /></div><div> Selected Options : ${checkedOptions} <br /></div>`;
            return {
              key: item.id,
              route: item.route,
              subroute: item.subRoute,
              searchedData: formData,
              mySearchName: item.mySearchName,
              searchedon: item.createdAt,
              stype: item.stype
            };
          });
          setPastSearches(itemsData);
          setLoading(false);
        } else if (status == 401)
          myCommonApiCall.signOut();
        else
          openNotificationWithIcon("error", "Error", message);
      })
  }

  useEffect(() => {
    setLoading(true);
    getSavedSearchData();
  }, []);

  const rowSelection = {
    type: 'radio',
    onSelect: (record) => {
      setIsRowSelected(true);
      setSelectedRecord(record);
      window.sessionStorage.setItem("pastSearchData", JSON.stringify(record.searchedData));

    }
  };

  const changeSavedRecordName = (values) => {
    setLoading(true);

    fetch(`${hostURL}api/usersearchdata/changeSearchRecordName/${toUpdatedRecordId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      body: JSON.stringify({ recordName: values.nrcsavedName })
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message === "Saved search Name was Updated successfully!") {
          getSavedSearchData();
          openNotificationWithIcon('success', 'Success', 'Record updated successfully.');
        } else {
          openNotificationWithIcon('error', 'Error', json.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Error', 'Failed to update record.');
        setLoading(false);
      });
    handleUpdateNameModalCancel();
    setToUpdatedRecordId(null);
  }

  const deleteRecord = (recordId) => {
    setLoading(true);
    fetch(`${hostURL}api/usersearchdata/${recordId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message === "Saved search was deleted successfully!") {
          openNotificationWithIcon('success', 'Success', 'Record deleted successfully.');
          setPastSearches(pastSearches.filter((item) => item.key !== recordId));
        } else {
          openNotificationWithIcon('error', 'Error', json.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Error', 'Failed to delete record.');
        setLoading(false);
      });
  };

  const searchAgain = (recordData) => {
    if (recordData.stype === "explorePractices") {
      window.sessionStorage.setItem("pastSearchData", JSON.stringify(recordData.searchedData));
      setSelectedRecord({
        state: recordData.searchedData.selectedState,
        county: recordData.searchedData.selectedCounty
      });
      window.location.href = "/practiceList";
    } else {
      alert("This is not a saved priorities search!");
    }
  };

  const DeleteButton = ({ record }) => (
    <>
      <Popconfirm
        title="Are you sure to delete this record?"
        onConfirm={() => deleteRecord(record.key)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger icon={<DeleteOutlined />} /> &nbsp;&nbsp;
      </Popconfirm>
      <Popconfirm
        title="Are you sure to Edit the Saved Name?"
        onConfirm={() => openUpdateNameModal(record.key)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger icon={<EditOutlined />} />
      </Popconfirm>
    </>
  );
  const getRankingScoreItems = (rankingData) => {
    let toReturn = ''
    rankingData && rankingData.forEach((item, i) => {
      for (let key in item) {
        toReturn += `<li key=${key + i}>
         ${key}: ${item[key]}</li>`;
        //  console.log(`${key}: ${item[key]}, ${i}`);
      }
    })
    return toReturn;
  }
  // const 

  const handleUpdateNameModalCancel = () => {
    setupdatedRecordNameModalOpen(false);
  };

  const openUpdateNameModal = (recordId) => {
    setToUpdatedRecordId(recordId);
    setupdatedRecordNameModalOpen(true);
  };

  const onFinishPassFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {/* <Header /> */}
      <Spin spinning={loading}>
        <Row >
          <Col span={20} offset={2}>
            {/* <h3> Past Searches</h3> */}
            {isRowSelected && (
              <Popconfirm
                title="Search Again"
                description="Are you sure to Search Selected Row Data Again ?"
                onConfirm={searchAgain}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button className="filter-button"><b><FileSearchOutlined /> Search Again</b></Button>
              </Popconfirm>)}

            {/* <p><Table
            rowSelection={rowSelection}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <div
                  style={{
                    margin: 0
                  }}
                >
                  {record.data}
                </div>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable"
            }}
            dataSource={pastSearches}
            bordered
            pagination={{
                pageSize: 30,
              }}
              scroll={{
                y: window.screen.height-300,
              }}
          />
          </p> */}
          </Col>
        </Row>

        <Row>
          {pastSearches && pastSearches.map((indSearch, index) => {
            if (
              indSearch.searchedData.selectedState &&
              indSearch.searchedData.selectedCounty &&
              indSearch.stype === "explorePractices"
            ) {
              return (
                <Col span={5} offset={1} key={index}>
                  <Card
                    className="savedSearch-card"
                    title={indSearch.mySearchName}
                    extra={<DeleteButton record={indSearch} />}
                  >
                    <div onClick={() => searchAgain(indSearch)}>
                      <ul className="pl5">
                        <li>
                          Selected State:&nbsp;
                          <b>{indSearch.searchedData.selectedState}</b>
                        </li>
                        <li>
                          Selected County:&nbsp;
                          <b>{indSearch.searchedData.selectedCounty}</b>
                        </li>
                        {indSearch.stype === "explorePractices" && (
                          <li>
                            Cover Type:
                            <ul className="stepsImages">
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step2CheckedTypes.includes(
                                  "aquaculture"
                                ) && (
                                    <Tooltip
                                      placement="topLeft"
                                      title={getLabels.aquaculture}
                                    >
                                      <img className="img_selected_cb" src={aquaculture} />
                                    </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step2CheckedTypes.includes(
                                  "cropland"
                                ) && (
                                    <Tooltip
                                      placement="topLeft"
                                      title={getLabels.cropland}
                                    >
                                      <img className="img_selected_cb" src={cropland} />
                                    </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step2CheckedTypes.includes(
                                  "timber"
                                ) &&
                                  (<Tooltip
                                    placement="topLeft"
                                    title={getLabels.timber}
                                  >
                                    <img className="img_selected_cb" src={timber} />
                                  </Tooltip>)
                                }
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step2CheckedTypes.includes(
                                  "pasture"
                                ) &&
                                  (<Tooltip
                                    placement="topLeft"
                                    title={getLabels.pasture}
                                  >
                                    <img className="img_selected_cb" src={pasture} />
                                  </Tooltip>)
                                }
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step2CheckedTypes.includes(
                                  "wetland"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.wetland}
                                ><img className="img_selected_cb" src={wetland} /></Tooltip>)}
                              </li>
                            </ul>
                          </li>
                        )}
                        {indSearch.stype === "explorePractices" && (
                          <li>
                            Priorities:
                            {/* <b>{indSearch.searchedData.step3CheckedTypes.join(', ')}</b> */}
                            <ul className="stepsImages">
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "aquatic"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.aquatic}> <img className="img_selected_cb" src={aquatic} />
                                </Tooltip>)}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "biggame"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.biggame}> <img className="img_selected_cb" src={biggame} />
                                </Tooltip>)}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "carbonstorage"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.carbonstorage}> (
                                  <img
                                    className="img_selected_cb"
                                    src={carbonstorage}
                                  />)</Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "drought"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.drought}>  <img className="img_selected_cb" src={drought} /></Tooltip>)}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "watertemperature"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.watertemperature}>
                                  <img
                                    className="img_selected_cb"
                                    src={watertemperature}
                                  /></Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "excesswind"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.excesswind}>
                                  <img className="img_selected_cb" src={excesswind} /></Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "flooding"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.flooding}>
                                  <img className="img_selected_cb" src={flooding} /></Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "forestbirds"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.forestbirds}>
                                  <img className="img_selected_cb" src={forestbirds} /></Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "grasslandbirds"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.grasslandbirds}>
                                  <img
                                    className="img_selected_cb"
                                    src={grasslandbirds}
                                  /></Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "pollinators"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.pollinators}>
                                  <img className="img_selected_cb" src={pollinators} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "snowdrift"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.snowdrift}>
                                  <img className="img_selected_cb" src={snowdrift} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "soilhealth"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.soilhealth}>
                                  <img className="img_selected_cb" src={soilhealth} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "watererosion"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.watererosion}>
                                  <img className="img_selected_cb" src={watererosion} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "waterfowl"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.waterfowl}>
                                  <img className="img_selected_cb" src={waterfowl} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "waterquality"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.waterquality}>
                                  <img className="img_selected_cb" src={waterquality} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "winderosion"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.winderosion}>
                                  <img className="img_selected_cb" src={winderosion} />
                                </Tooltip>
                                  )}
                              </li>
                              <li>
                                {indSearch.searchedData.step2CheckedTypes && indSearch.searchedData.step3CheckedTypes.includes(
                                  "priorityecosystem"
                                ) && (<Tooltip
                                  placement="topLeft"
                                  title={getLabels.priorityecosystem}>
                                  <img className="img_selected_cb" src={priorityecosystem} />
                                </Tooltip>
                                  )}
                              </li>
                            </ul>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Card>
                </Col>
              );
            }
          })}

        </Row>
      </Spin>
      <Modal
        title="Change Saved Search Record Name"
        open={updatedRecordNameModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleUpdateNameModalCancel()}
      >
        <Form
          colon={false}
          layout="horizontal"
          onFinish={changeSavedRecordName}
          onFinishFailed={onFinishPassFailed}
          autoComplete="off"
        >
          <Form.Item
            label="New Record Name"
            name="nrcsavedName"
            rules={[
              {
                required: true,
                message: "Please input your New Record Name!",
                whitespace: false,
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="&nbsp;">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </>
  );
}
export default PastActivity;