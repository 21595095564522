import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Alert, Popover, Row, Steps } from "antd";
import Step1 from "./Components/Step1";
import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";
import Step4 from "./Components/Step4";
import "./style.css";
import { useDirtyContext } from "../../utils/DirtyContext";

const items = [
  { title: "Location" },
  { title: "Land" },
  { title: "Priority" },
  { title: "Ranking" },
];

function WizardForm({
  wizardStep,
  setWizardStep,
  stateList,
  selectedState,
  stateChange,
  countyList,
  selectedCounty,
  countyChange,
  setClickedState,
  setClickedCounty,
  setClickedFips,
  selectedFips,
  selectedPriorities,
  selectedCheckboxes,
  setFilterOpen,
  setRankingScore,
  notselected,
}) {
  const [priorities, setPriorities] = useState([]);
  const navigate = useNavigate();
  const { isDirty, setIsDirty } = useDirtyContext();

  useEffect(() => {
    setIsDirty(true);
  }, []);

  const steps = [
    {
      step: 1,
      title: "Step1",
      content: (
        <Step1
          stateList={stateList}
          selectedState={selectedState}
          stateChange={stateChange}
          countyList={countyList}
          selectedCounty={selectedCounty}
          countyChange={countyChange}
          setClickedState={setClickedState}
          setClickedCounty={setClickedCounty}
          setClickedFips={setClickedFips}
          selectedFips={selectedFips}
        />
      ),
    },
    {
      step: 2,
      title: "Step2",
      content: (
        <Step2
          selectedPriorities={selectedPriorities}
          selectedCheckboxes={selectedCheckboxes}
        />
      ),
    },
    {
      step: 3,
      title: "Step3",
      content: (
        <Step3
          selectedPriorities={selectedPriorities}
          selectedCheckboxes={selectedCheckboxes}
        />
      ),
    },
    {
      step: 4,
      title: "Step4",
      content: (
        <Step4
          selectedCheckboxes={selectedCheckboxes}
          setRankingScore={setRankingScore}
        />
      ),
    },
  ];

  return (
    <>
      <Row>
        {notselected && (
          <Alert
            message="Please select State and County to view available practices."
            type="error"
            closable
          />
        )}
        <Col span={20} offset={2} className="mt-3">
          <Steps className="wizard-steps" current={wizardStep} items={items} />
          <div>{steps[wizardStep].content}</div>
          {/* <div
            style={{
              marginTop: 20,
            }}
          >
            {wizardStep > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Back
              </Button>
            )}
            {wizardStep < steps.length - 1 && (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => next()}
              >
                Next
              </Button>
            )}
            {wizardStep === steps.length - 1 && (
              <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => setFilterOpen(false)}
              >
                Confirm
              </Button>
            )}
          </div> */}
        </Col>
      </Row>
    </>
  );
}
export default WizardForm;
