import React, { createContext, useState } from "react";

export const ModuleContext = createContext({});

export const ModuleContextProvider = ({ children }) => {
  const [practiceList, setPracticeList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [fieldDetails, setFieldDetails] = useState({});
  const [practicePreference, setPracticePreference] = useState(null);
  const [priorityPreference, setPriorityPreference] = useState({landCoverTypes: [], priorities: []});
  const [eligibilityCriteria, setEligibilityCriteria] = useState({incomeCheck: false, cropHistory: false, ownerHistory: false});

  const value = {
    practiceList,
    updatePracticeList,
    featureList,
    updateFeatureList,
    fieldDetails,
    updateFieldDetails,
    practicePreference,
    updatePracticePreference,
    priorityPreference,
    updatePriorityPreference,
    eligibilityCriteria,
    updateEligibilityCriteria
  };
  
  function updatePracticeList(newState) {
    setPracticeList(() => {
      return newState;
    });
  };

  function updateFeatureList(newState) {
    setFeatureList(() => {
      return newState;
    });
  };
  
  function updateFieldDetails(newState) {
    setFieldDetails(() => {
      return newState;
    });
  };
  
  function updatePracticePreference(newState) {
    setPracticePreference(() => {
      return newState;
    });
  };

  function updatePriorityPreference(newState) {
    setPriorityPreference(() => {
      return newState;
    });
  };

  function updateEligibilityCriteria(newState) {
    setEligibilityCriteria(() => {
      return newState;
    });
  };

  return (
    <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>
  );
};