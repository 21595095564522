import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Map, { Source, Layer, Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Button, Col, Row, Tooltip } from "antd";
import { LuMap } from "react-icons/lu";
import polylabel from "polylabel";
import bbox from "@turf/bbox";
import { ModuleContext } from "../../../context/Context";
import DrawControl from "../Helpers/DrawControl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "../style.css";

function FieldMap({
  setMapZoomLevel,
  setMapViewBounds,
  selectedFips,
  setSelectedFips,
  setClickedState,
  setClickedCounty,
  zoomToCounty,
  userLocation,
  pinCenter,
  setPinCenter,
  fieldBoundary,
  selectedField,
  setSelectedField,
  drawingMode,
  editingMode,
  pinningMode,
}) {
  const mapRef = useRef();
  const [baseLayer, setBaseLayer] = useState(
    "mapbox://styles/mapbox/light-v11"
  );
  const [layerSelectOpen, setLayerSelectOpen] = useState(false);
  const [safeLayerOn, setSafeLayerOn] = useState(false);
  const [floodplainLayerOn, setFloodplainLayerOn] = useState(false);
  const [waterLayerOn, setWaterLayerOn] = useState(false);
  const [cursor, setCursor] = useState("auto");
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([
    "county-boundary",
  ]);
  const [fieldHighlightFilter, setFieldHighlightFilter] = useState([
    "in",
    "gid",
    "",
  ]);
  const [fieldSelectFilter, setFieldSelectFilter] = useState(["in", "gid", ""]);
  const [countyFips, setCountyFips] = useState("00000");
  const [countySelectFilter, setCountySelectFilter] = useState([
    "in",
    "FIPS",
    "",
  ]);
  const [countyHighlightFilter, setCountyHighlightFilter] = useState([
    "in",
    "FIPS",
    "",
  ]);
  const { featureList, updateFeatureList, practicePreference } =
    useContext(ModuleContext);
  const stateFilter = [
    "match",
    ["get", "STATE"],
    ["IA", "IL", "MO", "MS"],
    true,
    false,
  ];

  const handleLayerSelect = () => {
    setLayerSelectOpen(!layerSelectOpen);
  };

  const onClick = (e) => {
    if (
      interactiveLayerIds.includes("county-boundary") &&
      e.features.length &&
      !editingMode &&
      !drawingMode
    ) {
      const feature = e.features[0];
      setSelectedFips(feature.properties.FIPS);
      setClickedState(feature.properties.STATE);
      setClickedCounty(feature.properties.COUNTYNAME);
    }
    if (
      interactiveLayerIds.includes("field-boundary") &&
      e.features.length &&
      !editingMode
    ) {
      const feature = e.features[0];
      setSelectedField(feature);
      setFieldSelectFilter(["in", "gid", feature.id]);
    }
    if (pinningMode) {
      setPinCenter({ lng: e.lngLat.lng, lat: e.lngLat.lat });
    }
  };

  const onMouseEnter = () => {
    setCursor("pointer");
  };

  const onMouseLeave = () => {
    if (drawingMode || pinningMode) {
      setCursor("crosshair");
    } else {
      setCursor("auto");
    }
    setCountyHighlightFilter(["in", "FIPS", ""]);
    setFieldHighlightFilter(["in", "gid", ""]);
  };

  const onMouseMove = (e) => {
    if (
      interactiveLayerIds.includes("county-boundary") &&
      e.features.length &&
      !editingMode
    ) {
      const feature = e.features[0];
      setCountyHighlightFilter(["in", "FIPS", feature.properties.FIPS]);
    }
    if (
      interactiveLayerIds.includes("field-boundary") &&
      e.features.length &&
      !editingMode
    ) {
      const feature = e.features[0];
      setFieldHighlightFilter(["in", "gid", feature.id]);
    }
  };

  const onMove = (e) => {
    if (mapRef.current) {
      setMapViewBounds(mapRef.current.getBounds);
    }
    setCursor("grab");
  };

  const onMoveEnd = (e) => {
    if (drawingMode || pinningMode) {
      setCursor("crosshair");
    } else {
      setCursor("auto");
    }
  };

  const onZoom = (e) => {
    setMapZoomLevel(e.viewState.zoom);
  };

  const onUpdate = useCallback((e) => {
    const newList = [...featureList, e.features[0]];
    updateFeatureList(newList);
  }, []);

  const onDelete = useCallback((e) => {
    const newList = featureList.filter(
      (feature) => feature.id !== e.features[0].id
    );
    updateFeatureList(newList);
  }, []);

  useEffect(() => {
    if (typeof selectedFips !== "string")
      setSelectedFips(selectedFips?.toString());
    if (selectedFips) {
      setCountySelectFilter(["in", "FIPS", selectedFips]);
    }
  }, [selectedFips]);

  useEffect(() => {
    if (mapRef.current && userLocation.longitude && userLocation.latitude) {
      mapRef.current.fitBounds(
        [
          [userLocation.longitude - 0.005, userLocation.latitude - 0.005],
          [userLocation.longitude + 0.005, userLocation.latitude + 0.005],
        ],
        { padding: 40, duration: 1000 }
      );
      setBaseLayer("mapbox://styles/mapbox/satellite-streets-v12");
    }
  }, [userLocation]);

  useEffect(() => {
    if (typeof countyFips !== "string") {
      setCountyFips(countyFips?.toString());
    }

    if (mapRef.current) {
      var features = mapRef.current.querySourceFeatures("map-source", {
        sourceLayer: "c_08mr23-1pp4eg",
        filter: ["==", "FIPS", countyFips],
      });

      if (features.length) {
        // calculate the bounding box of the feature
        const [minLng, minLat, maxLng, maxLat] = bbox(features[0]);
        mapRef.current.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          { padding: 40, duration: 1000 }
        );
      } else {
        mapRef.current.fitBounds(
          [
            [-95.6, 30.3],
            [-89.1, 41.3],
          ],
          { padding: 40, duration: 1000 }
        );

        setTimeout(() => {
          var features = mapRef.current.querySourceFeatures("map-source", {
            sourceLayer: "c_08mr23-1pp4eg",
            filter: ["==", "FIPS", countyFips],
          });
          const [minLng, minLat, maxLng, maxLat] = bbox(features[0]);
          mapRef.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          );
        }, "1000");
      }
      setBaseLayer("mapbox://styles/mapbox/satellite-streets-v12");
    }
  }, [countyFips]);

  useEffect(() => {
    if (zoomToCounty) {
      setCountyFips(selectedFips);
    }
  }, [zoomToCounty, selectedFips]);

  useEffect(() => {
    if (mapRef.current && fieldBoundary) {
      setInteractiveLayerIds(["field-boundary"]);
      const [minLng, minLat, maxLng, maxLat] = bbox(fieldBoundary);
      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  }, [fieldBoundary]);

  useEffect(() => {
    if (drawingMode) {
      setCursor("crosshair");
    } else {
      setCursor("auto");
    }
  }, [drawingMode]);

  useEffect(() => {
    if (pinningMode) {
      setInteractiveLayerIds([]);
      setCursor("crosshair");
    } else {
      setCursor("auto");
    }
  }, [pinningMode]);

  useEffect(() => {
    if (mapRef.current && editingMode && selectedField) {
      // Must add the control to the map before adding the features
      const editControl = new MapboxDraw();
      mapRef.current.addControl(editControl);
      editControl.add(selectedField);
      editControl.changeMode("direct_select", { featureId: selectedField.id });
      mapRef.current.on("draw.update", (e) => {
        setSelectedField(e.features[0]);
      });
    }
  }, [editingMode]);

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken="pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
      initialViewState={{
        longitude: -91,
        latitude: 36,
        zoom: 4.5,
      }}
      style={{ width: "calc(100vw - 400px)", height: "90vh" }}
      mapStyle={baseLayer}
      cursor={cursor}
      interactiveLayerIds={interactiveLayerIds}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMove={onMove}
      onMoveEnd={onMoveEnd}
      onZoom={onZoom}
    >
      {drawingMode && (
        <DrawControl
          displayControlsDefault={false}
          controls={{
            polygon: "true",
            trash: "true",
          }}
          defaultMode="draw_polygon"
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
      <Tooltip placement="bottom" title="Layer Tool">
        <Button className="layer-select" onClick={handleLayerSelect}>
          <LuMap strokeWidth={3} />
        </Button>
      </Tooltip>
      {layerSelectOpen && (
        <div className="layer-options">
          <Col className="base-layers">
            <p>
              <b>Base Layers</b>
            </p>
            <p
              class={
                baseLayer === "mapbox://styles/mapbox/light-v11"
                  ? "layer-container-selected"
                  : "layer-container"
              }
            >
              <Button
                className="layer-button"
                onClick={() => setBaseLayer("mapbox://styles/mapbox/light-v11")}
              >
                <img
                  className="layer-thumbnail"
                  src="https://api.mapbox.com/styles/v1/mapbox/light-v11/static/-88.81,33.45,7.0,0,0/50x50?access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                />
                <label className="layer-label">Light Base Layer</label>
              </Button>
            </p>
            <p
              class={
                baseLayer === "mapbox://styles/mapbox/dark-v11"
                  ? "layer-container-selected"
                  : "layer-container"
              }
            >
              <Button
                className="layer-button"
                onClick={() => setBaseLayer("mapbox://styles/mapbox/dark-v11")}
              >
                <img
                  className="layer-thumbnail"
                  src="https://api.mapbox.com/styles/v1/mapbox/dark-v11/static/-88.81,33.45,7.0,0,0/50x50?access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                />
                <label className="layer-label">Dark Base Layer</label>
              </Button>
            </p>
            <p
              class={
                baseLayer === "mapbox://styles/mapbox/satellite-streets-v12"
                  ? "layer-container-selected"
                  : "layer-container"
              }
            >
              <Button
                className="layer-button"
                onClick={() =>
                  setBaseLayer("mapbox://styles/mapbox/satellite-streets-v12")
                }
              >
                <img
                  className="layer-thumbnail"
                  src="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/-88.81,33.45,7.0,0,0/50x50?access_token=pk.eyJ1IjoiY2h1Y2swNTIwIiwiYSI6ImNrMDk2NDFhNTA0bW0zbHVuZTk3dHQ1cGUifQ.dkjP73KdE6JMTiLcUoHvUA"
                />
                <label className="layer-label">Satellite Base Layer</label>
              </Button>
            </p>
          </Col>
          <hr />
          <Col className="supporting-layers">
            <p>
              <b>Supporting Layers</b>
            </p>
            <p>
              <input
                type="checkbox"
                checked={safeLayerOn}
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setSafeLayerOn(true);
                  } else {
                    setSafeLayerOn(false);
                  }
                }}
              />
              SAFE Boundaries
            </p>
            {/* <p>
              <input
                type="checkbox"
                checked={floodplainLayerOn}
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setFloodplainLayerOn(true);
                  } else {
                    setFloodplainLayerOn(false);
                  }
                }}
              />
              Floodplains
            </p>
            <p>
              <input
                type="checkbox"
                checked={waterLayerOn}
                onClick={(e) => {
                  if (e.currentTarget.checked) {
                    setWaterLayerOn(true);
                  } else {
                    setWaterLayerOn(false);
                  }
                }}
              />
              Water Features
            </p> */}
          </Col>
        </div>
      )}
      <div className="map-legend">
        <Col span={24}>
          <Row>
            <div className="legend-counties"></div>
            <b className="legend-label">Pilot State Counties</b>
          </Row>
          {safeLayerOn && (
            <>
              <hr />
              <Row>
                <b className="legend-title">SAFE Boundaries</b>
                <span>
                  <div className="legend-IL_PH"></div>
                  <div className="legend-label">
                    Illinois Pheasant Initiative
                  </div>
                </span>
                <span>
                  <div className="legend-IL_QI"></div>
                  <div className="legend-label">Illinois Quail Initiative</div>
                </span>
                <span>
                  <div className="legend-MO_QM"></div>
                  <div className="legend-label">Missouri Bob White Quail</div>
                </span>
                <span>
                  <div className="legend-MO_SE"></div>
                  <div className="legend-label">Missouri Sand Ecosystem</div>
                </span>
                <span>
                  <div className="legend-MS_BB"></div>
                  <div className="legend-label">Mississippi Black Bear</div>
                </span>
                <span>
                  <div className="legend-MS_BP"></div>
                  <div className="legend-label">
                    Mississippi Black Belt Prairie
                  </div>
                </span>
                <span>
                  <div className="legend-MS_BQ"></div>
                  <div className="legend-label">Mississippi Bobwhite Quail</div>
                </span>
              </Row>
            </>
          )}
          {floodplainLayerOn && (
            <>
              <hr />
              <Row>
                <div className="legend-floodplain"></div>
                <b className="legend-label">Floodplains</b>
              </Row>
            </>
          )}
          {waterLayerOn && (
            <>
              <hr />
              <Row>
                <div className="legend-water"></div>
                <b className="legend-label">Water Features</b>
              </Row>
            </>
          )}
        </Col>
      </div>
      {!fieldBoundary && (
        <>
          <Source
            id="map-source"
            type="vector"
            url="mapbox://chuck0520.5pq9omti"
            maxzoom={22}
            minzoom={0}
          >
            <Layer
              id="county-boundary"
              source-layer="c_08mr23-1pp4eg"
              type="fill"
              paint={{
                "fill-outline-color": "#800000",
                "fill-color": "transparent",
              }}
              minzoom={0}
              maxzoom={22}
              filter={stateFilter}
            />
            {baseLayer !== "mapbox://styles/mapbox/satellite-streets-v12" && (
              <Layer
                id="county-highlight"
                source-layer="c_08mr23-1pp4eg"
                type="line"
                paint={{
                  "line-color": "#800000",
                  "line-width": 3,
                  "line-opacity": 1,
                }}
                minzoom={0}
                maxzoom={22}
                filter={countyHighlightFilter}
              />
            )}
            {baseLayer !== "mapbox://styles/mapbox/satellite-streets-v12" && (
              <Layer
                id="county-select"
                source-layer="c_08mr23-1pp4eg"
                type="fill"
                paint={{
                  "fill-outline-color": "#800000",
                  "fill-color": "cyan",
                  "fill-opacity": 0.5,
                }}
                minzoom={0}
                maxzoom={22}
                filter={countySelectFilter}
              />
            )}
            {baseLayer !== "mapbox://styles/mapbox/satellite-streets-v12" && (
              <Layer
                id="county-labels"
                type="symbol"
                source="map-source"
                source-layer="c_08mr23-1pp4eg"
                layout={{
                  "text-field": ["get", "COUNTYNAME"],
                  "text-size": {
                    stops: [
                      [0, 0],
                      [4, 0],
                      [6, 10],
                      [8, 15],
                    ],
                  },
                }}
                paint={{ "text-color": "#800000" }}
                filter={stateFilter}
              />
            )}
          </Source>
          {pinCenter && (
            <Marker
              longitude={pinCenter.lng}
              latitude={pinCenter.lat}
              color="maroon"
            />
          )}
          {safeLayerOn && (
            <Source
              id="safe-source"
              type="vector"
              url="mapbox://chuck0520.4ex8miv2"
              maxzoom={22}
              minzoom={0}
            >
              <Layer
                id="safe-layer"
                source-layer="SAFE_A_STATES_GCS-9clxpi"
                type="fill"
                paint={{
                  "fill-outline-color": "black",
                  "fill-color": [
                    "match",
                    ["get", "CODE"],
                    "IL_PH",
                    "red",
                    "IL_QI",
                    "orange",
                    "MO_QM",
                    "green",
                    "MO_SE",
                    "yellow",
                    "MS_BB",
                    "brown",
                    "MS_BP",
                    "gray",
                    "MS_BQ",
                    "purple",
                    "transparent",
                  ],
                  "fill-opacity": 0.3,
                }}
              />
            </Source>
          )}
        </>
      )}
      {!!fieldBoundary && (
        <>
          <Source id="field-source" type="geojson" data={fieldBoundary}>
            <Layer
              id="field-boundary"
              type="fill"
              paint={{
                "fill-outline-color": "white",
                "fill-color": "white",
                "fill-opacity": 0.5,
              }}
            />
            <Layer
              id="field-boundary-highlight"
              type="line"
              paint={{
                "line-color": "cyan",
                "line-width": 2,
              }}
              filter={fieldHighlightFilter}
            />
            <Layer
              id="field-boundary-selected"
              type="fill"
              paint={{
                "fill-outline-color": "cyan",
                "fill-color": "cyan",
                "fill-opacity": 0.5,
              }}
              filter={fieldSelectFilter}
            />
          </Source>
          {safeLayerOn && (
            <Source
              id="safe-source"
              source-layer="SAFE_A_STATES_GCS-9clxpi"
              type="vector"
              url="mapbox://chuck0520.4ex8miv2"
              maxzoom={22}
              minzoom={0}
            >
              <Layer
                id="safe-layer"
                type="fill"
                paint={{
                  "fill-outline-color": "black",
                  "fill-color": [
                    "match",
                    ["get", "CODE"],
                    "IL_PH",
                    "red",
                    "IL_QI",
                    "orange",
                    "MO_QM",
                    "green",
                    "MO_SE",
                    "yellow",
                    "MS_BB",
                    "brown",
                    "MS_BP",
                    "gray",
                    "MS_BQ",
                    "purple",
                    "transparent",
                  ],
                  "fill-opacity": 0.3,
                }}
              />
            </Source>
          )}
        </>
      )}
    </Map>
  );
}
export default FieldMap;
