import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Checkbox, Form, Modal, Popover } from "antd";
import secureLocalStorage from "react-secure-storage";
import { ModuleContext } from "../../../context/Context";
import Step2 from "../../ExplorebyGoals/Components/Step2";
import Step3 from "../../ExplorebyGoals/Components/Step3";
import Step4 from "../../ExplorebyGoals/Components/Step4";

function NonGeoCheck() {
  const [step, setStep] = useState(1);
  const [showMoreSteps, setShowMoreSteps] = useState(false);
  const [generalDisclaimer, setGeneralDisclaimer] = useState(false);
  const [checkPassed, setCheckPassed] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [disableOkButton, setDisableOkButton] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const {
    priorityPreference,
    updatePriorityPreference,
    updateEligibilityCriteria,
  } = useContext(ModuleContext);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  function checkMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  useEffect(() => {
    setIsMobile(checkMobile());
  }, []);

  const handleOk = () => {
    if (step === 1) {
      if (showMoreSteps) {
        setStep(2);
      } else {
        setCheckPassed(true);
      }
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      setCheckPassed(true);
    }
  };

  const handleCancel = () => {
    if (step === 1) {
      setCheckPassed(false);
      setShowAlert(true);
    } else {
      setCheckPassed(true);
    }
  };

  const onSelectLandCoverTypes = (checkedItems) => {
    updatePriorityPreference({
      ...priorityPreference,
      landCoverTypes: checkedItems,
    });
    secureLocalStorage.setItem("priority-preference", {
      ...priorityPreference,
      landCoverTypes: checkedItems,
    });
  };

  const onSelectPriorities = (checkedItems) => {
    updatePriorityPreference({
      ...priorityPreference,
      priorities: checkedItems,
    });
    secureLocalStorage.setItem("priority-preference", {
      ...priorityPreference,
      priorities: checkedItems,
    });
  };

  const onSetRankingScore = (rankingScore) => {
    secureLocalStorage.setItem("ranking-score", rankingScore);
  };

  useEffect(() => {
    setShowMoreSteps(!secureLocalStorage.getItem("priority-preference"));
  }, []);

  useEffect(() => {
    if (generalDisclaimer) {
      setDisableOkButton(false);
    } else {
      setDisableOkButton(true);
    }
  }, [generalDisclaimer]);

  return (
    <>
      {!isMobile ? (
        <Modal
          className="filter-modal"
          width="60vw"
          centered={true}
          open={!checkPassed}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ disabled: disableOkButton ? true : false }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div className="nongeocheck-popup">
            {step === 1 && (
              <>
                <h2>
                  Please confirm the eligibility requirements below before
                  proceeding
                </h2>
                <Form form={form} id="nonGeoCheckForm">
                  <div className="nongeocheck-form">
                    <Form.Item name="generalDisclaimer" valuePropName="checked">
                      <Checkbox
                        className="custom-checkbox"
                        checked={generalDisclaimer}
                        onChange={(e) => setGeneralDisclaimer(e.target.checked)}
                      >
                        <span style={{ color: "red" }}>*</span>I acknowledge
                        that all information by or on this site is provided for{" "}
                        <b>informational purposes only</b> and is not to be
                        relied upon as a formal contractual offer or used as
                        fact in contractual negotiations. Please contact your
                        local USDA office for more information.
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Form>
                {!showAlert && (
                  <Alert
                    message={<b>Attention</b>}
                    description={
                      <p>
                        Please note that all following eligibility requirements
                        are necessary before trying to enroll your field in a
                        CRP practice:
                        <br />
                        1. Your Adjusted Gross Income (AGI) is less than
                        $900,000 for the last 3 taxable years.
                        <br />
                        2. The land being considered for the CRP has been
                        planted or considered planted to an agricultural
                        commodity for four of the last six crop years from 2012
                        to 2017.
                        <br />
                        3. You have owned or operated the land being considered
                        for the CRP for at least the past 12 months.
                        <br />
                        Please contact your local USDA office for more details
                        about eligibility.
                      </p>
                    }
                    type="warning"
                    showIcon
                    closable
                  />
                )}
                {showAlert && (
                  <Alert
                    message="Error"
                    description="Please confirm that you have acknowledged the mandatory disclaimer and click the OK button to proceed!"
                    type="error"
                    showIcon
                    closable
                  />
                )}
              </>
            )}

            {showMoreSteps && step === 2 && (
              <Step2
                selectedPriorities={onSelectLandCoverTypes}
                selectedCheckboxes={[]}
              />
            )}

            {showMoreSteps && step === 3 && (
              <Step3
                selectedPriorities={onSelectPriorities}
                selectedCheckboxes={[]}
              />
            )}

            {showMoreSteps && step === 4 && (
              <Step4
                setRankingScore={onSetRankingScore}
                selectedCheckboxes={[]}
              />
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          className="filter-modal"
          width="60vw"
          centered={true}
          open={!checkPassed}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          footer={
            <button className="regular-button" onClick={() => navigate(-1)}>
              Back
            </button>
          }
        >
          <div className="bandaid-container">
            <h1>Stay Tuned!</h1>
            <div className="bandaid-inner">
              <p>
                The CRP Menu Tool does not currently support your device's size,
                but this feature is currently in development, so check back
                soon.
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default NonGeoCheck;
