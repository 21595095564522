import React from "react";
import "../style.css";
import { Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Table = ({ newPracticeData }) => {
  const filteredPrograms = newPracticeData.programs.filter(
    (program) => program.period && program.period !== "false"
  );

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            <th></th>
            {filteredPrograms.map((program, index) => (
              <th key={index}>{program.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contract Period</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                <p>{`${program.period} Years`}</p>
              </td>
            ))}
          </tr>
          <tr>
            <td>32.5% Signup Incentive Payment (SIP)</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                {program.incentives.sip ? (
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>50% Practice Incentive Payment (PIP)</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                {program.incentives.pip ? (
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Climate - Smart Incentive</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                {program.incentives.climateSmart ? (
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Rental Rate Incentive</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                {program.incentives.retnalRateIncentive ? (
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>Rental Rate Adjustment</td>
            {filteredPrograms.map((program, index) => (
              <td key={index} data-program-name={program.name}>
                {program.incentives.rentalRateAdj ? (
                  <Tooltip placement="topLeft" title="Eligible">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip placement="topLeft" title="Not Eligible">
                    <CloseCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
