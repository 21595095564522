import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import secureLocalStorage from "react-secure-storage";
import {
  Col,
  Row,
  Table,
  Spin,
  notification,
  Card,
  Modal,
  Form,
  Input,
  Button,
  Tabs,
} from "antd";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";
import myCommonApiCall from "../../Common/apicalls";
import hostURL from "../../Common/constants";
import UserFavorites from "../UserFavorites";
import PastActivity from "../PastActivity";
import SavedPolygons from "../SavedPolygons";
import {
  UserOutlined,
  MailOutlined,
  SisternodeOutlined,
  BankOutlined,
  LoginOutlined,
  KeyOutlined,
  InfoCircleOutlined,
  HeartOutlined,
  InteractionOutlined,
  RadiusSettingOutlined,
} from "@ant-design/icons";

const authToken = secureLocalStorage.getItem("token");
const userId = secureLocalStorage.getItem("userRef");

function UserProfile() {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const [userProfileData, setUserProfileData] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const clickUpdateDetails = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const clickUpdatePassword = () => {
    setIsPassModalOpen(true);
  };

  const handlePasswordModalCancel = () => {
    setIsPassModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch(hostURL + "api/users/" + userId, {
      // credentials: 'include',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;

        if (status == 200) {
          setUserProfileData(json);
        } else if (status == 401) myCommonApiCall.signOut();
        else openNotificationWithIcon("error", "Error", message);
      });
    setLoading(false);
  }, []);

  const onFinishPasswordUpdate = (values) => {
    setLoading(true);
    fetch(hostURL + "api/users/changePassword/" + userId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setLoading(false);
        if (status == 200) {
          handleCancel();
          openNotificationWithIcon("success", "Success", message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else openNotificationWithIcon("error", "Error", message);
      });
  };
  const onFinishPassFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    setLoading(true);
    fetch(hostURL + "api/users/" + userId, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setLoading(false);
        if (status == 200) {
          handleCancel();
          openNotificationWithIcon("success", "Success", message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else openNotificationWithIcon("error", "Error", message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const tabItems = [
    {
      key: "1",
      // label: 'Favorite Practices',
      label: "Practices",
      children: <UserFavorites />,
      icon: <HeartOutlined />,
    },
    {
      key: "2",
      // label: 'Saved Priorities',
      label: "Priorities",
      children: <PastActivity />,
      icon: <InteractionOutlined />,
    },
    {
      key: "3",
      // label: 'Saved Fields',
      label: "Fields",
      children: <SavedPolygons />,
      icon: <RadiusSettingOutlined />,
    },
  ];

  return (
    <>
      <Header />
      <Spin spinning={loading}>
        <Row>
          <Col span={20} offset={2}>
            <p>&nbsp;</p>
            <Card
              title="User Profile"
              extra={
                <>
                  <a
                    href="javascript:void(0);"
                    className="detailsUpdateLink"
                    onClick={clickUpdateDetails}
                  >
                    <InfoCircleOutlined />
                    Update Info
                  </a>
                  &nbsp; | &nbsp;
                  <a
                    href="javascript:void(0);"
                    className="detailsUpdateLink"
                    onClick={clickUpdatePassword}
                  >
                    <KeyOutlined />
                    Change Password
                  </a>
                </>
              }
            >
              <table className="userProfileData">
                <tr>
                  <td>
                    <UserOutlined /> Name
                  </td>
                  <td className="text-bold">
                    {userProfileData.firstname + " " + userProfileData.lastname}
                  </td>
                </tr>
                <tr>
                  <td>
                    <MailOutlined /> Email
                  </td>
                  <td className="text-bold">{userProfileData.emailid}</td>
                </tr>
                <tr>
                  <td>
                    <SisternodeOutlined /> Affiliation
                  </td>
                  <td className="text-bold">{userProfileData.affiliation}</td>
                </tr>
                <tr>
                  <td>
                    <BankOutlined /> Organization
                  </td>
                  <td className="text-bold">{userProfileData.organization}</td>
                </tr>
                <tr>
                  <td>
                    <LoginOutlined /> Last login
                  </td>
                  <td className="text-bold">{userProfileData.last_login}</td>
                </tr>
              </table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Tabs defaultActiveKey="1" items={tabItems} />
          </Col>
        </Row>
      </Spin>
      <Footer />
      <Modal
        title="Update Info"
        open={isModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleCancel()}
      >
        <Form
          colon={false}
          layout="horizontal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // autoComplete="off"
          scrollToFirstError
          initialValues={{
            ["firstname"]: userProfileData.firstname,
            ["lastname"]: userProfileData.lastname,
          }}
        >
          <Form.Item
            label="First Name"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="&nbsp;">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Change Password"
        open={isPassModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handlePasswordModalCancel()}
      >
        <Form
          colon={false}
          layout="horizontal"
          onFinish={onFinishPasswordUpdate}
          onFinishFailed={onFinishPassFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Current Password"
            name="cpwd"
            rules={[
              {
                required: true,
                message: "Please type your current password",
                whitespace: false,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newpwd"
            rules={[
              {
                required: true,
                message: "Please set your new password",
                whitespace: false,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Re-Type Password"
            name="renewpwd"
            dependencies={["newpwd"]}
            rules={[
              {
                required: true,
                message: "Please type your new password again",
                whitespace: false,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newpwd") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The new passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label="&nbsp;">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  );
}

export default UserProfile;
