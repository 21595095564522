import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from 'react-secure-storage';
import parse from 'html-react-parser';
import { Col, Row, Table, Spin, notification, Popconfirm, Button, Card, Tooltip,Modal, Form, Input } from "antd";
import {  FileSearchOutlined, DeleteOutlined, EditOutlined, SettingOutlined  } from "@ant-design/icons";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdRebaseEdit } from "react-icons/md";
// import Header from "../../Components/Header";
// import Footer from "../../Components/Footer";
import myCommonApiCall from "../../Common/apicalls";
import hostURL from '../../Common/constants';
import "./style.css";

const authToken = secureLocalStorage.getItem("token");
const userId = secureLocalStorage.getItem("userRef");

function SavedPolygons() {
  const [loading, setLoading] = useState(false);
  const [pastSearches, setPastSearches] = useState(false);
  const [searchAgainData, setSearchAgainData] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [toUpdatedRecordId, setToUpdatedRecordId] = useState(null);
  const [updatedRecordNameModalOpen, setupdatedRecordNameModalOpen] = useState(false);
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message
    });
  };

  const getSavedSearchData = () =>{
    fetch(hostURL+"api/usersearchdata/" + userId, {
      // credentials: 'include',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken
      }
    })
    .then((response) => {
      return new Promise((resolve) =>
        response.json().then((json) => {
          resolve({
            status: response.status,
            ok: response.ok,
            json
          });
        })
      );
    })
    .then(({ status, json, ok }) => {
      const message = json.message;

      if (status == 200) {
        const itemsData = json.map((item) => {
          const formData= JSON.parse(item.formData);
          // const checkedOptions =  (formData.step2CheckedTypes)? ((formData.step2CheckedTypes).concat(formData.step3CheckedTypes)).join(', '): '';
          // const childrenData = `<div>Selected State: <b>${
          //   formData.selectedState
          // }</b>, Selected County: <b>${formData.selectedCounty}</b> <br /></div><div> Selected Options : ${checkedOptions} <br /></div>`;
          return {
            key: item.id,
            route: item.route,
            subroute: item.subRoute,
            searchedData: formData,
            mySearchName: item.mySearchName,
            searchedon: item.createdAt,
            stype: item.stype
          };
        });
        setPastSearches(itemsData);
        setLoading(false);
      } else if (status==401) {
        myCommonApiCall.signOut();
      } else {
        openNotificationWithIcon("error", "Error", message);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSavedSearchData();
  }, []);

  const rowSelection = {
    type: 'radio',
    onSelect: (record) => {
      setIsRowSelected(true);
      window.sessionStorage.setItem("pastSearchData", JSON.stringify(record.searchedData));
    }
  };

  const changeSavedRecordName = (values) => {
    setLoading(true);

    fetch(`${hostURL}api/usersearchdata/changeSearchRecordName/${toUpdatedRecordId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
      body: JSON.stringify({ recordName: values.nrcsavedName })
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message === "Saved search Name was Updated successfully!") {
          getSavedSearchData();
          openNotificationWithIcon('success', 'Success', 'Record updated successfully.');
        } else {
          openNotificationWithIcon('error', 'Error', json.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Error', 'Failed to update record.');
        setLoading(false);
      });
      handleUpdateNameModalCancel();
      setToUpdatedRecordId(null);
  };

  const deleteRecord = (recordId) => {
    setLoading(true);
    fetch(`${hostURL}api/usersearchdata/${recordId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authToken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message === "Saved search was deleted successfully!") {
          openNotificationWithIcon('success', 'Success', 'Record deleted successfully.');
          setPastSearches(pastSearches.filter((item) => item.key !== recordId));
        } else {
          openNotificationWithIcon('error', 'Error', json.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        openNotificationWithIcon('error', 'Error', 'Failed to delete record.');
        setLoading(false);
      });
  };
  
  const searchAgain = (recordData)=>{
    if(recordData.stype=='explorePractices')
    {
    window.sessionStorage.setItem("pastSearchData", JSON.stringify(recordData.searchedData));
    window.location.href='/practiceList';
    }else{
      alert('its an Check Eligibility')
    }
  };

  const DeleteButton = ({ record }) => (
    <>
      <Popconfirm
        title="Are you sure to delete this record?"
        onConfirm={() => deleteRecord(record.key)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger icon={<DeleteOutlined />} /> &nbsp;&nbsp;
      </Popconfirm>
      <Popconfirm
        title="Are you sure to edit this name?"
        onConfirm={() => openUpdateNameModal(record.key)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" danger icon={<EditOutlined />} />
      </Popconfirm>
    </>
  );

  const getRankingScoreItems= (rankingData)=>{
    let toReturn = '';
    rankingData && rankingData.forEach((item, i) => {
      for(let key in item) {
        toReturn+= `<li key=${key+i}>
         ${key}: ${item[key]}</li>`;
         //  console.log(`${key}: ${item[key]}, ${i}`);
      }
    });
    return toReturn;
  };

  const handleUpdateNameModalCancel = () => {
    setupdatedRecordNameModalOpen(false);
  };

  const openUpdateNameModal = (recordId) => {
    setToUpdatedRecordId(recordId);
    setupdatedRecordNameModalOpen(true);
  };

  const onFinishPassFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {/* <Header /> */}
      <Spin spinning={loading}>
      <Row >
        <Col span={20} offset={2}>
            {isRowSelected && (
              <Popconfirm
                title="Search Again"
                description="Are you sure to go back to this search result?"
                onConfirm={searchAgain}
                okText="Yes"
                cancelText="No"
              >
              <Button className="filter-button"><b><FileSearchOutlined /> Search Again</b></Button>
              </Popconfirm>
            )}
        </Col>
      </Row>

      <Row>
        {pastSearches && pastSearches.map((indSearch, index) => {
          if (
            indSearch.searchedData.selectedState &&
            indSearch.searchedData.selectedCounty &&
            indSearch.stype === "checkEligibility"
          ) {
            return (
              <Col span={5} offset={1}  key={index}>
                <Card
                  className="savedSearch-card"
                  title={indSearch.mySearchName}
                  actions={[
                    // <div
                    //   onClick={() => {
                    //     navigate("/Map");
                    //   }}
                    // >
                    //   <MdRebaseEdit />
                    //   {" "}
                    //   Edit Polygon
                    // </div>,
                    <div
                      onClick={() => {
                        secureLocalStorage.setItem("field-details", indSearch.searchedData.data);
                        secureLocalStorage.setItem("priority-preference", {
                          landCoverTypes: indSearch.searchedData.step2CheckedTypes,
                          priorities: indSearch.searchedData.step3CheckedTypes
                        });
                        secureLocalStorage.setItem("ranking-score", indSearch.searchedData.rankingScore);
                        navigate("/FieldReport");
                      }}
                    >
                      <HiOutlineDocumentReport />
                      {" "}
                      Review Report
                    </div>,
                  ]}
                  cover={<img alt={indSearch.mySearchName} src={indSearch.searchedData.mapimage} height="200"/>}
                  extra={<DeleteButton record={indSearch} />}
                >
                  <ul className="pl5">
                    <li>
                      Field State:
                      {" "}
                      <b>{indSearch.searchedData.selectedState}</b>
                    </li>
                    <li>
                      Field County:
                      {" "}
                      <b>{indSearch.searchedData.selectedCounty}</b>
                    </li>
                  </ul>
                </Card>
              </Col>
            );
          }
        })}
      </Row>
      </Spin>
      <Modal
        title="Change Saved Search Record Name"
        open={updatedRecordNameModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleUpdateNameModalCancel()}
      >
        <Form
          colon={false}
          layout="horizontal"
          onFinish={changeSavedRecordName}
          onFinishFailed={onFinishPassFailed}
          autoComplete="off"
        >
          <Form.Item
            label="New Field Name"
            name="nrcsavedName"
            rules={[
              {
                required: true,
                message: "Please name your field here",
                whitespace: false,
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="&nbsp;">
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <Footer /> */}
      {contextHolder}
    </>
  );
}
export default SavedPolygons;