import React, { useState } from "react";
import { BrowserRouter as WithOutHash, Route, Routes } from "react-router-dom";
import Home from "../modules/Home";
import About from "../modules/About";
import CRPBasics from "../modules/CRPBasics";
import CRPMenu from "../modules/CRPMenu";
import ProjectTeam from "../modules/ProjectTeam";
import Contact from "../modules/Contact";
import Workshops from "../modules/Workshops";
import PracticeList from "../modules/PracticeList";
import PracticeReport from "../modules/PracticeReport";
import Practice from "../modules/Practice";
import EligibilityCheck from "../modules/EligibilityCheck";
import FieldReport from "../modules/FieldReport";
import LoginPage from "../modules/LoginPage";
import RegistrationPage from "../modules/RegistrationPage";
import PastActivity from "../modules/PastActivity";
import UserProfile from "../modules/UserProfile";
import PrivacyPolicy from "../modules/PrivacyPolicy";
import PrintView from "../modules/PrintView";
import UserFavorites from "../modules/UserFavorites";
import FieldPrint from "../modules/FieldPrint";

export default function AppRoutes() {
  const [practiceNumber, setPracticeNumber] = useState("");
  const [userFavoritePractices, setUserFavoritePractices] = useState([]);
  return (
    <>
      <WithOutHash>
        <Routes>
          <Route
            path="/"
            exact
            element={<Home />}
            forceRefresh={true}
            hideMenu={false}
          />
          <Route path="/About" exact element={<About />} />
          <Route path="/CRPBasics" exact element={<CRPBasics />} />
          <Route path="/CRPMenu" exact element={<CRPMenu />} />
          <Route path="/ProjectTeam" exact element={<ProjectTeam />} />
          <Route path="/Contact" exact element={<Contact />} />
          <Route path="/Workshops" exact element={<Workshops />} />
          <Route
            path="/PracticeList"
            exact
            element={
              <PracticeList
                userFavoritePractices={userFavoritePractices}
                setUserFavoritePractices={setUserFavoritePractices}
              />
            }
          />
          <Route path="/PracticeReport" exact element={<PracticeReport />} />
          <Route
            path="/Practice/:id/:pdfname/:prtitle"
            exact
            element={<Practice practiceNumber={practiceNumber} />}
          />
          <Route path="/Map" exact element={<EligibilityCheck />} />
          <Route
            path="/FieldReport"
            exact
            element={
              <FieldReport userFavoritePractices={userFavoritePractices} />
            }
          />
          <Route path="/UserLogin" exact element={<LoginPage />} />
          <Route
            path="/UserRegistration"
            exact
            element={<RegistrationPage />}
          />
          <Route path="/PastActivity" exact element={<PastActivity />} />
          <Route path="/UserFavorites" exact element={<UserFavorites />} />
          <Route path="/UserProfile" exact element={<UserProfile />} />
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
          <Route
            path="/PracticeList/Print"
            exact
            element={
              <PrintView
                userFavoritePractices={userFavoritePractices}
                setUserFavoritePractices={setUserFavoritePractices}
              />
            }
          />
          <Route
            path="/FieldReport/Print"
            exact
            element={
              <FieldPrint
                userFavoritePractices={userFavoritePractices}
                setUserFavoritePractices={setUserFavoritePractices}
              />
            }
          />
        </Routes>
      </WithOutHash>
    </>
  );
}
