import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  Card,
  Select,
  Modal,
  notification,
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { v4 as uuid } from 'uuid';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import hostURL from "../../Common/constants";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "./style.css";

function RegistrationPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toShowSpinner, setToShowSpinner] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };


  useEffect(()=>{
    loadCaptchaEnginge(6); 
  });

  const onFinish = (values) => {
    let user_captcha = values.myCaptchaVal;
    let verification_id = uuid();
    let flow_url = "https://prod-123.westus.logic.azure.com:443/workflows/fdf8525fa5f343e5a3a5d85632ab63a4/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=MdEo-rK0R1Ujubg3Y6AM37cVi4RJrw9FCWlf0txel-o";
    setToShowSpinner(true);
    if (validateCaptcha(user_captcha)) {
    const formData = {
      firstname: values.firstname,
      lastname: values.lastname,
      emailid: values.email,
      username: values.uname,
      password: values.password,
      phone: values.phone,
      organization: values.organization,
      affiliation: values.affiliation && values.affiliation.toString(),
      zipcode: values.zipcode,
      createdAt: new Date().toLocaleDateString(),
    };

    // Send the post request to Power Automate URL to trigger the email
    let body = {
      "email": values.email,
      "firstname": values.firstname,
      "lastname": values.lastname,
      "url": hostURL + "api/users/verification/" + verification_id
    };
    let request = new XMLHttpRequest();
    request.open("POST", flow_url, true);
    request.setRequestHeader("Content-Type", "application/json");
    request.onreadystatechange = function () {
      if (this.readyState === 4) {
        request.onreadystatechange = null;
        if (this.status === 200) {
          console.log(this.response);
        } else {
          console.log(this.statusText);
        }
      }
    };
    request.send(JSON.stringify(body));

    fetch(hostURL + "api/users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) => {
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
        );
      })
      .then(({ status, json, ok }) => {
        const message = json.message;
        setToShowSpinner(false);

        if (status == 200) {
          openNotificationWithIcon("success", "Success", message);
          window.location.assign("/UserLogin");
        } else openNotificationWithIcon("error", "Error", message);
      });
    }else{
      setToShowSpinner(false);
      alert('Invalid Captcha');
      openNotificationWithIcon("error", "Error", 'Invalid Captcha');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Header />
      {contextHolder}
      <Row>
        <Col span={12} offset={6}>
          <Card title="Registration" className="registrationForm">
            <Form
              name="registrationForm"
              colon={false}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your Email ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="cnfemail"
                label="Confirm Email"
                dependencies={["email"]}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please re input your Email ID!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("email") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The Email Id that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    max: 10,
                    min: 10,
                    message: "Please input your Contact number!"
                  }
                ]}
              >
                <Input addonBefore="+1" maxLength={10} />
              </Form.Item> */}
              <Form.Item label="Zipcode" name="zipcode">
                <Input />
              </Form.Item>

              <Form.Item label="Affiliation" name="affiliation">
                <Select mode="multiple" allowClear>
                  <Select.Option value="">Choose...</Select.Option>
                  <Select.Option value="landowner">Landowner</Select.Option>
                  <Select.Option value="usdastaff">USDA Staff</Select.Option>
                  <Select.Option value="conversationpartner">
                    Conversation Partner
                  </Select.Option>
                  <Select.Option value="academic">
                    Academic / Research
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="organization"
                label="Organization"
                rules={[
                  {
                    type: "string",
                    message: "Please input your Organization!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    min: 6,
                    message: "Please input your password, Min 6 Char",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="cnfpassword"
                dependencies={["password"]}
                label=" Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please re input your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The Password that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item label="&nbsp;" name="myCaptchaVal">
                <div className="myCaptcha">
        <LoadCanvasTemplate  reloadText="Reload" reloadColor="#000"/>
        <Input placeholder="Enter Above Captcha"  name="myCaptchaVal"/>

         </div>
                </Form.Item>
              <Form.Item
                name="isTermsAccepted"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Should accept Terms & Conditions")
                          ),
                  },
                ]}
              >
                <Checkbox>
                  I have read the{" "}
                  <button onClick={showModal} className="textBlack readTerms">
                    Terms & Conditions
                    <DoubleRightOutlined />
                  </button>
                </Checkbox>
              </Form.Item>
              <Space>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  loading={toShowSpinner}
                >
                  Submit
                </Button>
                <Button
                  className="login-form-button"
                  htmlType="reset"
                  disabled={toShowSpinner}
                >
                  Reset
                </Button>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
      <Footer />
      <Modal
        title="CRP Menu Tool User Profile Terms and Conditions"
        open={isModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={() => handleCancel()}
      >
        <p>
          By creating an account for CRP Menu Tool, you agree to our Terms and Conditions and Privacy Policy, confirming that you are at least 18 years old and will provide accurate information during registration. You are responsible for maintaining your account security and ensuring your use complies with applicable laws.
        </p>
        <p>
          We reserve the right to terminate accounts for violations and may update these terms at our discretion. All content is protected, and any disputes will be governed by the laws of Mississippi.
        </p>
        <p>
          For questions, please contact us at <a href="mailto:crpmenu@gri.msstate.edu" style={{color: "maroon"}}>crpmenu@gri.msstate.edu</a>.
        </p>
      </Modal>
    </>
  );
}
export default RegistrationPage;
